import { StatusCell, ChangesCell, EditableCell, StatusCellSimple, 
    ChangesLogCell, DivCell, DateCell, CustomChangesLogCell, RowChangesLogCell, UserAndDateCell,
    ElementValueCell, ItemCell, SimpleDivCell, CellWithSymbol, FactoryLogCell, 
    RevisionChangesCell, CheckboxCell, CommentsDivCell
} from '../common/Cell';
import {
    RevisionStatus,
    RevisionStatusText,
    ElementStatusText,
    EntityStatus,
    UserInputType,
    Runtime
} from '../../helpers/enums';
import {
    SUPPORTS_ALL_FACTORIES,
    getTypeById,
    userInputsAreSame,
    getUnitTypeById,
    revisionPropertiesAreSame,
    getActionStatusText,
    getFactoryString
} from '.';
import {
    getTruncatedValue,
    getValueString,
    getEmptyCellDiv,
    getCellDiv
} from '../../helpers/commonHelpers';
import { fuzzyMultipleFn } from '../common/ColumnFilters';

const maxLength = 500;

const getRevisionStatusText = (revisionStatus) => {
    if (revisionStatus === RevisionStatus.LATEST_REVISION) {
        return RevisionStatusText.LATEST_REVISION;
    }

    if (revisionStatus === RevisionStatus.EARLIER_REVISION) {
        return RevisionStatusText.EARLIER_REVISION;
    }

    return RevisionStatusText.NO_REVISION;
}

const getRevision = (revisions, status) => {
    if (revisions?.length > 0) {
        return revisions.find(r => r.status === status);
    }
    return null;
}

const getPublisher = (revisions, status) => {
    var revision = getRevision(revisions, status);
    if (revision) {
        return revision.updatedBy;
    }
}

const getPublishingDate = (revisions, status) => {
    var revision = getRevision(revisions, status);
    if (revision) {
        return revision.updatedDate;
    }
}

const CriterionCell = ({ value, maxLength = 0, usedRuntime = 0 }) => {
    return (<CommentsDivCell
        value={maxLength > 0 ? getTruncatedValue(value, maxLength) : value}
        className={maxLength > 0 ? "line-clamp" : ""}
        usedRuntime={usedRuntime}
    />);
}

const getCriterionCellWithLuaRuntime = ({ value, maxLength = 0 }) => {
    return (<CriterionCell
        value={value}
        maxLength={maxLength}
        usedRuntime={Runtime.LUA}
    />);
}

const getCriterionCellWithJsRuntime = ({ value, maxLength = 0 }) => {
    return (<CriterionCell
        value={value}
        maxLength={maxLength}
        usedRuntime={Runtime.JS}
    />);
}

const FormulaCell = ({ value, maxLength, usedRuntime = 0 }) => {
    if (!value || value.length === 0) {
        return getEmptyCellDiv();
    }
    return (<CommentsDivCell
        value={maxLength > 0 ? getTruncatedValue(value, maxLength) : value}
        className={maxLength > 0 ? "line-clamp" : ""}
        usedRuntime={usedRuntime}
    />);
}

const getFormulaCellWithLuaRuntime = ({ value, maxLength }) => {
    return (<FormulaCell
        value={value}
        maxLength={maxLength}
        usedRuntime={Runtime.LUA}
    />);
}

const getFormulaCellWithJsRuntime = ({ value, maxLength }) => {
    return (<FormulaCell
        value={value}
        maxLength={maxLength}
        usedRuntime={Runtime.JS}
    />);
}

const getUserInputs = ({ revisionData }) => {
    let elementInput = revisionData.input;
    if (revisionData.valueType?.userInputs?.length > 0) {
        elementInput = revisionData.valueType.userInputs.map(i => i.code + ' = ' + i.value).join(' ');
    }
    return elementInput;
}

const UserInputsCell = ({ value, revisionData, maxLength = 0, usedRuntime = 0 }) => {
    //console.log('input value', value, revisionData);
    if (revisionData?.valueType?.userInputs?.length > 0) {
        return (
            <div className="whitespace-nowrap d-flex flex-column">
                {
                    revisionData.valueType.userInputs.map((input, index) => {
                        return (<span key={"cell_input_" + index}>{input.code + " = " + input.value}</span>)
                    })
                }
            </div>
        );
    }

    return (<CommentsDivCell
        value={maxLength > 0 ? getTruncatedValue(value, maxLength) : value}
        className={maxLength > 0 ? "line-clamp" : ""}
        usedRuntime={usedRuntime}
    />);
}

const getUserInputsCellWithLuaRuntime = ({ value, revisionData, maxLength = 0 }) => {
    return (<UserInputsCell
        value={value}
        revisionData={revisionData}
        maxLength={maxLength}
        usedRuntime={Runtime.LUA}
    />);
}

const getUserInputsCellWithJsRuntime = ({ value, revisionData, maxLength = 0 }) => {
    return (<UserInputsCell
        value={value}
        revisionData={revisionData}
        maxLength={maxLength}
        usedRuntime={Runtime.JS}
    />);
}

const getUserInputsString = ({ revisionData: row }) => {
    if (!row || row.isGlobal) {
        return ""
    }
    if (row.userInputs?.length > 0) {
        return row.userInputs.map(i => i.code).join(", ");
    }
    return "";
}


const EnvironmentColumns = () => {
    return [{
            header: 'Stage',
            id: 'stage', // key is needed for settings modal!
            accessorFn: row => getRevisionStatusText(row.stageStatus), 
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            maxFilterWidth: 50,
            className: 'w-50px',
            cell: StatusCell
        },
        {
            header: 'Prod',
            id: 'prod', // key is needed for settings modal!
            accessorFn: row => getRevisionStatusText(row.prodStatus), 
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            maxFilterWidth: 50,
            className: 'w-50px',
            cell: StatusCell
        }
    ];
}

const UpdaterAndPubliherColumns = () => {
    return [
    {
        header: 'Updated by',
        accessorKey: 'updatedBy',
        disabled: false,
        default: false,
        filterFn: fuzzyMultipleFn,
        cell: DivCell
    },
    {
        header: 'Updated',
        accessorKey: 'updatedDate',
        disabled: false,
        default: true,
        filterFn: fuzzyMultipleFn,
        className: 'w-100px',
        cell: DateCell
    },
    {
        header: 'Publ. by (stage)',
        id: 'publishedToStageBy',
        accessorFn: row => getPublisher(row.revisions, EntityStatus.STAGE),
        disabled: false,
        default: false,
        filterFn: fuzzyMultipleFn,
        cell: DivCell
    },
    {
        header: 'Publ. (stage)',
        id: 'publishedToStageDate',
        accessorFn: row => getPublishingDate(row.revisions, EntityStatus.STAGE),
        disabled: false,
        default: false,
        filterFn: fuzzyMultipleFn,
        className: 'w-100px',
        cell: DateCell
    },
    {
        header: 'Publ. by (prod)',
        id: 'publishedToProdBy',
        accessorFn: row => getPublisher(row.revisions, EntityStatus.PROD),
        disabled: false,
        default: false,
        filterFn: fuzzyMultipleFn,
        cell: DivCell
    },
    {
        header: 'Publ. (prod)',
        id: 'publishedToProdDate',
        accessorFn: row => getPublishingDate(row.revisions, EntityStatus.PROD),
        disabled: false,
        default: false,
        filterFn: fuzzyMultipleFn,
        className: 'w-100px',
        cell: DateCell
        }];
}

const ElementColumnsBase = () => {
    return [{
            header: 'Elem. code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: ChangesCell
        },
        {
            header: 'Factory',
            id: 'factory',
            accessorFn: row => getFactoryString({ revisionData : row}),
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getMethod={getFactoryString}
                    compareValues={true}
                    getCellMethod={getCellDiv}
                    symbolId={"factorySymbol"} />)
            }
        },
        {
            header: 'Criterion',
            accessorKey: 'criterion',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { original }, column: { id }, table }) => {
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getCellMethod={table.usedRuntime === Runtime.JS ? getCriterionCellWithJsRuntime : getCriterionCellWithLuaRuntime}
                    compareMethod={revisionPropertiesAreSame}
                    maxLength={maxLength} />);
            }
        },
        {
            header: 'Type',
            accessorKey: 'valueType.code',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Input',
            id: 'input',
            accessorFn: row => getUserInputs({ revisionData: row }),
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { original }, column: { id }, table }) => {
                //console.log('table for input', table);
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getMethod={getUserInputs}
                    getCellMethod={table.usedRuntime === Runtime.JS ? getUserInputsCellWithJsRuntime : getUserInputsCellWithLuaRuntime}
                    compareMethod={userInputsAreSame}
                    maxLength={maxLength} />);
            }
        },
        {
            header: 'Value',
            id: 'value',
            accessorFn: row => {
                return getValueString(row.value);
            },
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major whitespace-pre-wrap',
            cell: ({ row: { original }, column: { id } }) => {
                return (<ElementValueCell row={{ original: original }} column={{ id: id }} maxLength={maxLength} />);
            }
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getCellMethod={getCellDiv}
                    compareValues={true} />);
            }
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Internal ID',
            accessorKey: 'internalId',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
    ];
}

export const ElementColumns = () => {
    return [
        ...EnvironmentColumns(),
        {
            header: 'Item',
            id: 'item',
            accessorFn: row => row.itemCode + ' ' + row.itemDescription,
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ row: { original } }) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<ItemCell code={original.itemCode} description={original.itemDescription} />);
            }
        },
        ...ElementColumnsBase(),
        ...UpdaterAndPubliherColumns()
    ];
}

export const ElementColumnsUserAndDateCombined = () => {
    return [
        ...EnvironmentColumns(),
        {
            header: 'Item code',
            accessorKey: 'itemCode',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Item desc.',
            accessorKey: 'itemDescription',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        ...ElementColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<UserAndDateCell value={getValue()} date={original.updatedDate} />);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate} />);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate} />);
            }
        },
    ];
}

export const ElementLogColumns = () => {
    return [
        {
            header: 'Stage',
            accessorKey: 'publishedToStageDate',
            cell: StatusCellSimple
        },
        {
            header: 'Prod',
            accessorKey: 'publishedToProdDate',
            cell: StatusCellSimple
        },
        {
            header: 'Elem. code',
            accessorKey: 'code',
            className: 'text-major',
            cell: ChangesLogCell
        },
        {
            header: 'Factory',
            accessorKey: 'factory',
            cell: ({ getValue, row: { index, original }, column: {id}, table}) => {
                return (<FactoryLogCell value={getValue()}
                    row={{ index: index, original: original }}
                    column={{ id: id }}
                    table={table} />);
            }
        },
        {
            header: 'Criterion',
            accessorKey: 'criterion',
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
                const compareCriterion = (row, previous) => {
                    //console.log('row, previous', row, previous);
                    if (!row && !previous) {
                        return true;
                    }
                    if ((!row && previous) || (row && !previous)) {
                        return false;
                    }
                    
                    var current = row.criterion ?? "";
                    return current.localeCompare(previous.criterion ?? "") === 0;
                }
                return (<RowChangesLogCell value={getValue()}
                    row={{ index: index, original: original }}
                    column={{ id: id }}
                    table={table}
                    getMethod={table.usedRuntime === Runtime.JS ? getCriterionCellWithJsRuntime : getCriterionCellWithLuaRuntime}
                    compareMethod={compareCriterion}
                />);
            }
        },
        {
            header: 'Type',
            accessorKey: 'valueType.code',
            cell: ChangesLogCell
        },
        {
            header: 'Input',
            accessorKey: 'input',
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { index, original }, column: { id }, table }) =>{
                return (<RowChangesLogCell value={getValue()}
                    row={{ index: index, original: original }}
                    column={{ id: id }}
                    table={table}
                    getMethod={table.usedRuntime === Runtime.JS ? getUserInputsCellWithJsRuntime : getUserInputsCellWithLuaRuntime}
                    compareMethod={userInputsAreSame} />);
            }
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            cell: ChangesLogCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            cell: ChangesLogCell
        },
        {
            header: 'Internal ID',
            accessorKey: 'internalId',
            cell: ChangesLogCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: DivCell
        },
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: {original}}) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<UserAndDateCell value={getValue()} date={original.updatedDate}/>);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate}/>);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            disabled: false,
            default: false,
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate}/>);
            }
        },
    ]
}

export const ElementLogHistoryColumns = () => {
    return [
        {
            header: 'Stage',
            accessorFn: row => getRevisionStatusText(row.stageStatus),
            cell: StatusCell
        },
        {
            header: 'Prod',
            accessorFn: row => getRevisionStatusText(row.prodStatus),
            cell: StatusCell
        },
        {
            header: 'Item code',
            accessorKey: 'itemCode',
            cell: DivCell
        },
        {
            header: 'Elem. code',
            accessorKey: 'code',
            className: 'text-major',
            cell: DivCell
        },
        {
            header: 'Factory',
            accessorKey: 'factory',
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.factorySymbol', original.factorySymbol);
                return (<CellWithSymbol value={getValue()} symbol={original.factorySymbol} />);
            }
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: DivCell
        },
        {
            header: 'Operation',
            id: 'operation',
            accessorFn: row => getActionStatusText(row.action),
            className: 'text-nowrap',
            cell: DivCell
        },
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<UserAndDateCell value={getValue()} date={original.updatedDate} />);
            }
        }
    ]
}

export const ItemBaseColumns = () => {
    return [
        {
            header: 'Category',
            id: 'category',
            accessorFn: row => row.category ?? "",
            disabled: true,
            default: true,
            enableGlobalFilter: true,
            filterFn: 'equalsString',
            cell: DivCell
        },
        {
            header: 'Item code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            enableGlobalFilter: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: DivCell
        },
        {
            header: 'Item desc.',
            accessorKey: 'description',
            disabled: false,
            default: true,
            enableGlobalFilter: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Internal notes',
            id: 'internalNotes',
            accessorFn: row => row.internalNotes ?? "",
            disabled: false,
            default: true,
            enableGlobalFilter: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Internal ID',
            id: 'internalId',
            accessorFn: row => row.internalId ?? "",
            disabled: false,
            default: false,
            enableGlobalFilter: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
    ]
}

export const ItemColumns = () => {
    return [
        {
            header: 'Elements',
            id: 'elementStatus',
            accessorFn: row => {
                // For filtering we need to return the values shown in filter dropdown!
                if (row.elementStatus === EntityStatus.PROD) {
                    return ElementStatusText.ALL;
                }
                if (row.elementStatus === EntityStatus.STAGE || row.elementStatus === EntityStatus.INWORK) {
                    return ElementStatusText.SOME;
                }
                return ElementStatusText.WITHOUT;
            },
            disabled: true,
            default: true,
            enableGlobalFilter: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-100px',
            cell: ({ row: { original } }) => {
                const value = original.elementStatus;//console.log('value', value, typeof value);
                if (value === EntityStatus.PROD){
                    return (<div className="bg-success text-custom-light">{ElementStatusText.PUBLISHED}</div>)
                }
                if (value === EntityStatus.STAGE || value === EntityStatus.INWORK){
                    return (<div className="bg-warning">{ElementStatusText.UNPUBLISHED}</div>)
                }
                return (<div className="bg-secondary text-custom-light">{ElementStatusText.NONE}</div>)
            }
        },
        {
            header: 'Flags',
            accessorKey: 'flagSymbols',
            disabled: false,
            default: true,
            enableGlobalFilter: false,
            filterFn: fuzzyMultipleFn,
            className: 'w-50px',
            cell: DivCell
        },
        ...ItemBaseColumns()
    ]
}

export const FlagColumns = () => {
    return [
        {
            header: 'Flag',
            accessorKey: 'name',
            className: 'w-100px',
            cell: ({ getValue, row: { original } }) => {
                const flag = original.symbol + ' ' + getValue();
                return (<SimpleDivCell value={flag} className="text-major" />);
            }
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: SimpleDivCell
        },
        {
            header: 'Created by',
            accessorKey: 'createdBy',
            className: 'text-nowrap w-160px',
            cell: ({ getValue, row: { original } }) => {
                //console.log('original.updatedDate', original.updatedDate);
                return (<UserAndDateCell value={getValue()} date={original.createdDate} />);
            }
        }
    ];
}

export const ValueTypeColumnsBase = () => {
    return [
        ...EnvironmentColumns(),
        {
            header: 'Value code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: ({ getValue, row: { original }, column: { id } }) => {
                if (original.isGlobal) {
                    return (<div>{getValue()}</div>);
                }
                return (<ChangesCell getValue={getValue} row={{ original: original }} column={{ id: id }} />);
            }
        },
        {
            header: 'Description',
            accessorKey: 'description',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Factory',
            accessorKey: 'factories',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                if (original.isGlobal) {
                    return (<CellWithSymbol value={SUPPORTS_ALL_FACTORIES} symbol={original.factorySymbol} />);
                }
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    symbolId="factorySymbol"
                    getCellMethod={getCellDiv}
                    compareValues={true} />);
            }
        },
        {
            header: 'Base type',
            id: 'type', // key is needed for settings modal!
            accessorFn: row => getTypeById({ value: row.type }),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getMethod={getTypeById}
                    getCellMethod={getCellDiv}
                    compareValues={true} />);
            }
        },
        {
            header: 'Free-form',
            accessorKey: 'inputDefinedLater',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'User input',
            id: 'userInputs',
            accessorFn: row => getUserInputsString({ revisionData : row }),
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getMethod={getUserInputsString}
                    getCellMethod={getCellDiv}
                    compareValues={true} />);
            }
        },
        {
            header: 'Formula',
            accessorKey: 'formula',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { original }, column: { id }, table }) => {
                //console.log('original', original);
                if (original.isGlobal) {
                    return getEmptyCellDiv();
                }
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getCellMethod={table.usedRuntime === Runtime.JS ? getFormulaCellWithJsRuntime : getFormulaCellWithLuaRuntime}
                    compareValues={true}
                    maxLength={maxLength} />);
            }
        },
        {
            header: 'Unit type',
            id: 'unitType',
            accessorFn: row => getUnitTypeById({ value: row.unitType }),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getMethod={getUnitTypeById}
                    getCellMethod={getCellDiv}
                    compareValues={true} />);
            }
        },
        {
            header: 'Unit group',
            accessorKey: 'unitGroup',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
    ];
}

export const ValueTypeColumns = () => {
    return [
        ...ValueTypeColumnsBase(),
        ...UpdaterAndPubliherColumns(),
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const ValueTypeColumnsUserAndDateCombined = () => {
    return [
        ...ValueTypeColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.updatedDate} />);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate} />);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate} />);
            }
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const ValueTypeLogColumns = () => {
    return [
        {
            header: 'Stage',
            accessorKey: 'publishedToStageDate',
            cell: StatusCellSimple
        },
        {
            header: 'Prod',
            accessorKey: 'publishedToProdDate',
            cell: StatusCellSimple
        },
        {
            header: 'Value code',
            accessorKey: 'code',
            className: 'text-major',
            cell: ChangesLogCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: ChangesLogCell
        },
        {
            header: 'Factory',
            accessorKey: 'factories',
            cell: ({ getValue, row: { original, index }, column: {id}, table}) => {
                return (<FactoryLogCell value={getValue()} row={{ index: index, original: original }} column={{ id: id }} table={table} />);
            }
        },
        {
            header: 'Base type',
            accessorKey: 'type',
            cell: ({ getValue, row: { index }, column: { id }, table }) => {
                return (<CustomChangesLogCell value={getValue()} row={{ index: index }} column={{ id: id }} table={table} getMethod={getTypeById}/>)
            }
        },
        {
            header: 'Free-form',
            accessorKey: 'inputDefinedLater',
            cell: ChangesLogCell
        },
        {
            header: 'User input',
            accessorKey: 'userInputs',
            cell: ChangesLogCell
        },
        {
            header: 'Formula',
            accessorKey: 'formula',
            className: 'whitespace-pre-wrap',
            cell: ({ getValue, row: { original, index }, column: { id }, table }) => {
                //console.log('props', table.getCoreRowModel().flatRows);
                //console.log('table', table);
                if (original.isGlobal){
                    return getEmptyCellDiv();
                }
                const compareFormula = (row, previous) => {
                    if (!row && !previous) {
                        return true;
                    }

                    if ((!row && previous) || (row && !previous)) {
                        return false;
                    }

                    return row.formula.localeCompare(previous.formula) === 0;
                }
                return (<RowChangesLogCell value={getValue()}
                    row={{ index: index, original: original }}
                    column={{ id: id }}
                    table={table}
                    getMethod={table.usedRuntime === Runtime.JS ? getFormulaCellWithJsRuntime : getFormulaCellWithLuaRuntime}
                    compareMethod={compareFormula}
                />);
            }
        },
        {
            header: 'Unit type',
            accessorKey: 'unitType',
            cell: ({ getValue, row: { index }, column: { id }, table }) => {
                return (<CustomChangesLogCell value={getValue()} row={{ index: index }} column={{ id: id }} table={table} getMethod={getUnitTypeById} />)
            }
        },
        {
            header: 'Unit group',
            accessorKey: 'unitGroup',
            cell: ChangesLogCell
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            cell: ChangesLogCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            cell: ChangesLogCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: DivCell
        },
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.updatedDate}/>);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate}/>);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: { original }}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate}/>);
            }
        },
    ];
}

export const UserInputColumns = (inputName) => {
    return [
        {
            header: 'Code',
            accessorKey: 'code',
            cell: DivCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: DivCell
        },
        {
            header: 'Type',
            accessorKey: 'type',
            cell: DivCell
        },
        {
            header: inputName ?? 'Input',
            accessorKey: 'value',
            cell: ({ getValue, row: { original, index }, column: { id }, table }) => {
                //console.log('original', original);
                if (original.typeId === UserInputType.BOOLEAN) {
                    return (<CheckboxCell id={original.uniqueId}
                        index={index}
                        checked={getValue()}
                        disabled={false}
                        table={table}
                    />);
                }
                return <EditableCell initialValue={getValue()} index={index} id={id} table={table} type={original.typeId} />
            }
        },
    ];
}

const ParameterColumnsBase = () => {
    return [
        ...EnvironmentColumns(),
        {
            header: 'Param. code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: ChangesCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Factory',
            accessorKey: 'factory',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    symbolId="factorySymbol"
                    getCellMethod={getCellDiv}
                    compareValues={true} />);
            }
        },
        {
            header: 'Type',
            id: 'type', // key is needed for settings modal!
            accessorFn: row => getTypeById({ value: row.type }),
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<RevisionChangesCell value={getValue()}
                    row={{ original: original }}
                    column={{ id: id }}
                    getMethod={getTypeById}
                    getCellMethod={getCellDiv}
                    compareValues={true} />);
            }
        },
        {
            header: 'Value',
            accessorKey: 'value',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major whitespace-pre-wrap',
            cell: ({ getValue, row: { original }, column: { id } }) => {
                return (<ChangesCell getValue={getValue} row={{ original: original }} column={{ id: id }} maxLength={maxLength} />);
            }
        },
        {
            header: 'Has unit',
            accessorKey: 'hasUnit',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Internal ID',
            accessorKey: 'internalId',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ChangesCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        }
    ];
}

export const ParameterColumns = () => {
    return [
        ...ParameterColumnsBase(),
        ...UpdaterAndPubliherColumns(),
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const ParameterColumnsUserAndDateCombined = () => {
    return [
        ...ParameterColumnsBase(),
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.updatedDate} />);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            filterFn: fuzzyMultipleFn,
            className: 'text-nowrap',
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate} />);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            disabled: false,
            default: false,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue, row: { original } }) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate} />);
            }
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const ParameterLogColumns = () => {
    return [
        {
            header: 'Stage',
            accessorKey: 'publishedToStageDate',
            cell: StatusCellSimple
        },
        {
            header: 'Prod',
            accessorKey: 'publishedToProdDate',
            cell: StatusCellSimple
        },{
            header: 'Param. code',
            accessorKey: 'code',
            className: 'text-major',
            cell: ChangesLogCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            cell: ChangesLogCell
        },
        {
            header: 'Factory',
            accessorKey: 'factory',
            cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
                return (<FactoryLogCell value={getValue()} row={{ index: index, original: original }} column={{ id: id }} table={table} />);
            }
        },
        {
            header: 'Type',
            accessorKey: 'type',
            cell: ChangesLogCell
        },
        {
            header: 'Value',
            accessorKey: 'value',
            className: 'text-major whitespace-pre-wrap',
            cell: ChangesLogCell
        },
        {
            header: 'Has unit',
            accessorKey: 'hasUnit',
            cell: ChangesLogCell
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            cell: ChangesLogCell
        },
        {
            header: 'Internal notes',
            accessorKey: 'internalNotes',
            cell: ChangesLogCell
        },
        {
            header: 'Internal ID',
            accessorKey: 'internalId',
            cell: ChangesLogCell
        },
        {
            header: 'Comments',
            accessorKey: 'comments',
            cell: DivCell
        },
        {
            header: 'Updated by',
            accessorKey: 'updatedBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: {original}}) => {
                return (<UserAndDateCell value={getValue()} date={original.updatedDate}/>);
            }
        },
        {
            header: 'Publ. by (stage)',
            accessorKey: 'publishedToStageBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: {original}}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToStageDate}/>);
            }
        },
        {
            header: 'Publ. by (prod)',
            accessorKey: 'publishedToProdBy',
            className: 'text-nowrap',
            cell: ({ getValue, row: {original}}) => {
                return (<UserAndDateCell value={getValue()} date={original.publishedToProdDate}/>);
            }
        },
    ];
}

export const ClientInputsColumns = () => {
    return [
        {
            header: 'Input code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
            cell: DivCell
        },
        {
            header: 'Description',
            accessorKey: 'description',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Type',
            accessorKey: 'type',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Namespace',
            accessorKey: 'namespace',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
            cell: DivCell
        },
    ];
}

export const CategoryColumns = () => {
    return [
        {
            header: 'Category',
            accessorKey: 'name',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
        },
        {
            header: 'Items',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            className: 'w-50px text-minor',
        },
    ];
}

export const UnitColumns = () => {
    return [
        {
            header: 'Group',
            accessorKey: 'group',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
        },
        {
            header: 'Unit code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
        },
        {
            header: 'Description',
            accessorKey: 'description',
            disabled: false,
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
        },
    ];
}

export const ElementTypeColumns = () => {
    return [
        {
            header: 'Element code',
            accessorKey: 'code',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
            className: 'text-major',
        },
        {
            header: 'Description',
            accessorKey: 'description',
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: DivCell
        },
        {
            header: 'Value types',
            accessorKey: 'types',
            default: true,
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                const value = getValue();
                //console.log('value', value);
                if (!value || !value.includes(';;')){
                    return (<div>{value}</div>);
                }
                let codes = value.split(';;');//console.log('codes', codes);
                if (!codes || codes.length === 0){
                    return (<div>{value}</div>);
                }
                return (<div>{codes.map((code, index) => {return(<span key={index}>{code}<br></br></span>);}) }</div>);
            }
        },
        {
            header: 'Refs.',
            accessorKey: 'references',
            default: true,
            enableColumnFilter: false,
            className: 'w-50px text-minor',
        },
    ];
}

export const UnitGroupColumns = () => {
    return [
        {
            header: 'Group',
            accessorKey: 'name',
            disabled: true,
            default: true,
            filterFn: fuzzyMultipleFn,
        },
        {
            header: 'Units',
            accessorKey: 'references',
            disabled: false,
            default: true,
            enableColumnFilter: false,
            enableGlobalFilter: false,
            className: 'w-50px text-minor',
        },
    ];
}
import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import adminStorageActions from '../../actions/adminStorageActions';
import AdminFormBase from './AdminFormBase';
import { EntityType, Runtime, RuntimeTexts } from '../../helpers/enums';
import { CustomSelect } from '../common/CustomSelect';

const schema = yup.object().shape({
    code: yup.string().label('Code').required().max(50),
    name: yup.string().label('Description').required().max(50),
    runtime: yup.object().shape({ value: yup.number().required('Select a runtime') }).nullable().required('Select a runtime')
});

function StorageForm({
    onStorageAdded,
    msalInstance,
    resetErrors = false
}) {
    const { handleSubmit, reset, setError, formState: { errors, isValid }, control, setValue } = useForm({
        resolver: yupResolver(schema), mode: 'onChange'
    });

    const runtimeList = [{ label: RuntimeTexts.LUA, value: Runtime.LUA },
        { label: RuntimeTexts.JS, value: Runtime.JS }];

    const setValues = (data) => {
        if (data.runtime) {
            data.scriptingRuntime = data.runtime.value;
        }
    }

    const setFormValues = (storage) => {
        let id = storage.id ?? 0;
        let code = storage.code ?? "";
        let name = storage.name ?? "";
        // By default Lua scripting runtime is selected.
        let runtime = storage.scriptingRuntime === Runtime.JS ?
            { label: RuntimeTexts.JS, value: Runtime.JS } :
            { label: RuntimeTexts.LUA, value: Runtime.LUA };
        setValue("id", id);
        setValue("code", code);
        setValue("name", name);
        setValue("runtime", runtime);
    }

    return (
        <AdminFormBase
            entityType={EntityType.STORAGE}
            setActiveAction={adminStorageActions.setActiveStorage}
            setEntitiesAction={adminStorageActions.setStorages}
            onEntityAdded={onStorageAdded}
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            setFormValues={setFormValues}
            msalInstance={msalInstance}
            resetErrors={resetErrors}
            setValues={setValues}
        >
            <Controller
                name="id"
                control={control}
                defaultValue=""
                render={({ field }) => <Input {...field} type="hidden" />}
            />
            <FormGroup className="required form-group">
                <Label for="code">Storage code</Label>
                <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field} type="text" />}
                />
                {errors.code && <p className="text-danger">{errors.code.message}</p>}
            </FormGroup>
            <FormGroup className="required form-group">
                <Label for="name">Storage description</Label>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input {...field} type="text" />}
                />
                {errors.name && <p className="text-danger">{errors.name.message}</p>}
            </FormGroup>
            <FormGroup row className="required form-group">
                <Label for="runtime">Scripting runtime</Label>
                <Controller
                    name="runtime"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <CustomSelect {...field}
                        options={runtimeList}
                        //isDisabled={status === Action.VIEW}
                    />}
                />
                {errors.group && <p className="text-danger">{errors.group.message}</p>}
            </FormGroup>
        </AdminFormBase>
    );
}

export default StorageForm;
import React, { useState, useEffect } from 'react';
import { callAPI } from '../../../helpers/api';
import { useForm } from "react-hook-form";
import ModalBase from './ModalBase';
import { Action } from '../../../helpers/enums';
import SimpleCustomTable from '../SimpleCustomTable';
import { Col } from 'reactstrap';
import { firstLetterToUpperCase } from '../../../helpers/commonHelpers';
import { CustomSpinner } from '../CustomSpinner';

export default function LogModal({
    isOpen,
    toggle,
    entityProperties,
    baseId,
    subTitle,
    columns,
    parentTitle,
    parentColumns,
    user,
    msalInstance,
    children,
    runtime = 0
}) {
    const properties = entityProperties ?? []; //console.log('runtime', runtime);

    const { reset, setError, formState: { errors } } = useForm();
    const [parent, setParent] = useState(null);
    const [revisions, setRevisions] = useState([]);
    const [loading, setIsLoading] = useState(false);

    const entityTxt = firstLetterToUpperCase(properties.single);
    const logModalTitle = entityTxt + "'s " + Action.LOG + " (" + revisions.length + ")";

    const populateRevisions = async (id) => {
        if (isOpen) { //console.log('Get revisions url', url);
            reset();
            setIsLoading(true); 
            if (properties.baseUrl){
                let url = properties.baseUrl + "revisions/" + id;
                const result = await callAPI(url, msalInstance, user);//console.log('result', result);
                const resultJson = result.json;
                if (result.errorMsg && !resultJson){
                    setError("getError", {
                        type:"manual",
                        message: result.errorMsg
                    }); 
                }
                else{
                    if (resultJson && resultJson.parent && resultJson.revisions){
                        setParent(resultJson.parent);
                        setRevisions(resultJson.revisions);
                    }
                    else{
                        setRevisions(resultJson);
                    }
                }
            }
        }
        setIsLoading(false);
    }

    const modalIsClosed = () => {
        reset();
        setParent(null);
        setRevisions([]);
    }

    useEffect(() => {
        if (isOpen && baseId > 0){
            populateRevisions(baseId);
        }
    }, [baseId, isOpen]);// eslint-disable-line react-hooks/exhaustive-deps

    return(
        <ModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={logModalTitle}
            subTitle={subTitle}
            className="modal-xxl"
            onModalIsClosing={modalIsClosed}
        >
            {children}
            {parentTitle &&
                <div className="mb-2">
                    <h4>{parentTitle}</h4>
                </div>
            }
            { parent && parentColumns && parentColumns.length > 0 &&
                <>
                    <Col lg={12} xl={8}>
                        <SimpleCustomTable
                        tableColumns={parentColumns}
                        tableData={[parent]}
                        runtime={runtime}
                        />
                    </Col>
                    <div className="mb-2 mt-2">
                        <h4>{entityTxt} revisions</h4>
                    </div>
                </>
            }
            {loading && <CustomSpinner />}
            {revisions.length > 0 && <SimpleCustomTable
                className="table-with-states"
                tableColumns={columns}
                tableData={revisions}
                hiddenColumns={["actions"]}
                exportData={{ show: true, fileName: 'logData' }}
                runtime={runtime}
            />}
            { errors.getError && <p className="text-danger">{errors.getError.message}</p>}
        </ModalBase>
    )
}
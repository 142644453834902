import { Role, RoleText, UserStatus, UserStatusText, IsText, PublishingRole } from '../../helpers/enums';
import { getPublishingRoleTextById } from './'
import { SimpleDivCell } from '../common/Cell';
import { fuzzyMultipleFn } from '../common/ColumnFilters';

const getFactoryString = (factories, env) => {
    if (factories?.length > 0) {
        return getPublishingRoleTextById(env) + ": " + factories.map(r => r.factory).join(", ");
    }
    return "";
}

const getPublishingRoleString = (contributorRoles) => {
    if (!(contributorRoles?.length > 0)) {
        return "";
    }
    const prodStr = getFactoryString(contributorRoles.filter(r => r.publishingRole === PublishingRole.PROD), PublishingRole.PROD);
    const stageStr = getFactoryString(contributorRoles.filter(r => r.publishingRole === PublishingRole.STAGE), PublishingRole.STAGE);
    //console.log('prodStr, stageStr', prodStr, stageStr)
    if (prodStr?.length > 0) {
        if (stageStr?.length > 0) {
            return prodStr + ", " + stageStr;
        }
        return prodStr;
    }

    if (stageStr?.length > 0) {
        return stageStr;
    }

    return "";
}

const getPublishingRoles = (roles) => {
    let publishingRoles = "";
    // Contributor roles
    if (roles?.length > 0) {
        const contributorRoles = roles.filter(r => r.role === Role.CONTRIBUTOR);
        if (contributorRoles.length > 0) {
            publishingRoles = getPublishingRoleString(contributorRoles);
        }
    }
    return publishingRoles;
}

const UserColumnsBase = () => {
    return [
        {
            header: 'Name',
            accessorKey: 'name',
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} className="text-major" />);
            }
        },
        {
            header: 'Email',
            accessorKey: 'email',
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} toLower={true} />);
            }
        },
        {
            header: 'Status',
            id: 'status',
            accessorFn: row => {
                if (row.status === UserStatus.ACTIVE) {
                    return UserStatusText.ACTIVE;
                }
                return UserStatusText.DISABLED;
            },
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} className={getValue() === UserStatusText.ACTIVE ? "" : "highlightTag neg"} />);
            }
        }
    ];
}

export const ADUserColumns = () => {
    return [
        {
            header: 'Name',
            accessorKey: 'displayName',
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} className="text-major" />);
            }
        },
        {
            header: 'User principal',
            accessorKey: 'userPrincipalName',
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} />);
            }
        },
        {
            header: 'Email',
            accessorKey: 'mail',
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} toLower={true} />);
            }
        },
    ];
}

export const UserColumns = () => {
    return [
        ...UserColumnsBase(),
        {
            header: 'Storage owner',
            id: 'storageOwner',
            accessorFn: row => {
                if (row.roles?.length > 0) {
                    //console.log('roles', row.roles);
                    const ownerRoles = row.roles.filter(r => r.role === Role.OWNER);
                    if (ownerRoles?.length > 0) {
                        return ownerRoles.map(o => o.storage).sort().join (', ');
                    }
                    return ''
                }
                return '';
            },
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} />);
            }
        }
    ];
}

export const UserWithRolesColumns = (storageId) => {
    return [
        ...UserColumnsBase(),
        {
            header: 'Owner',
            id: 'isStorageOwner',
            accessorFn: row => {
                //console.log('storageId', storageId)
                if (storageId === 0) {
                    return IsText.YES;
                }
                if (row.storageOwnerIds?.length > 0 && storageId > 0 &&
                    row.storageOwnerIds.includes(storageId)) {
                    return IsText.YES;
                }
                return IsText.NO;
            },
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                const value = getValue(); //console.log(storageId, value);
                return (<SimpleDivCell value={value} className={value === IsText.YES ? "highlightTag pos" : ""} />);
            },
        },
        {
            header: 'Factory roles',
            id: 'factoryRoles',
            accessorFn: row => {
                let roles = "";
                // Contributor roles
                if (row?.roles?.length > 0) {
                    const contributorRoles = row.roles.filter(r => r.role === Role.CONTRIBUTOR);
                    if (contributorRoles.length > 0) {
                        roles = "C: " + contributorRoles.map(r => r.factory).join(", ");
                    }
                }
                // Reader roles
                const readerRoles = row.roles.filter(r => r.role === Role.READER);
                if (readerRoles.length > 0) {
                    roles = roles + (roles.length > 0 ? ", " : "") +
                        "R: " + readerRoles.map(r => r.factory).join(", ");
                }
                return roles;
            },
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} />);
            }
        },
        {
            header: 'Publishing',
            id: 'publishing',
            accessorFn: row => getPublishingRoles(row?.roles),
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} />);
            }
        }
    ];
}

export const UserRoleColumns = () => {
    return [
        {
            header: 'Factory code',
            accessorKey: 'factory',
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} className="text-major" />);
            }
        },
        {
            header: 'Role',
            accessorKey: 'role',
            cell: ({ getValue }) => {
                let txt = RoleText.NONE;
                switch (getValue()) {
                    case Role.READER:
                        txt = RoleText.READER;
                        break;
                    case Role.CONTRIBUTOR:
                        txt = RoleText.CONTRIBUTOR;
                        break;
                    case Role.ADMIN:
                        txt = RoleText.ADMIN;
                        break;
                    default:
                        txt = RoleText.NONE;
                }
                return (<SimpleDivCell value={txt} />);
            }
        },
        {
            header: 'Publishing',
            accessorKey: 'publishingRole',
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getPublishingRoleTextById(getValue(), false)} />);
            }
        }
    ];
}

export const StorageUserColumns = () => {
    return [
        {
            header: 'Name',
            accessorKey: 'name',
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} className="text-major" />);
            }
        },
        {
            header: 'Email',
            accessorKey: 'email',
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} toLower={true} />);
            }
        },
    ];
}

export const FactoryColumns = () => {
    return [
        {
            header: 'Factory code',
            accessorKey: 'code',
            filterFn: fuzzyMultipleFn,
            cell: ({ getValue }) => {
                return (<SimpleDivCell value={getValue()} className="text-major" />);
            }
        },
        {
            header: 'Factory description',
            accessorKey: 'name',
            filterFn: fuzzyMultipleFn,
        }
    ];
}
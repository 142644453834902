import React, { useState, useEffect, forwardRef } from "react";
import Editor from '@monaco-editor/react';
import { default as SimpleEditor } from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-lua';
import 'prismjs/themes/prism.css';
import { Runtime } from '../../helpers/enums';

const MIN_HEIGHT = 35;
const MAX_HEIGHT = 600;
const MIN_COUNT_OF_LINES = 1;
const LINE_HEIGHT = 20;

export const CustomSimpleEditor = forwardRef((props, ref) => {
    const [customValue, setCustomValue] = useState(props.value); //console.log('props & customValue', props, '"' + customValue + '"');
    const { value, onChange, ...properties } = props; //console.log('properties & customValue', properties, '"' + customValue + '"');
    const scriptLanguage = props.language === Runtime.JS ? languages.javascript : languages.lua;

    const onValueChange = (value) => {
        setCustomValue(value);
        if (props.onChange) {
            props.onChange(value);
        }
    }

    useEffect(() => {
        setCustomValue(props.value);
    }, [props.value]);

    return (
        <SimpleEditor {...properties} ref={ref}
        //className="ms-2"
            value={customValue}
            tabSize={2}
            onValueChange={customValue => onValueChange(customValue)}
            highlight={customValue => highlight(customValue, scriptLanguage)}
        />
    )
})

export const CustomEditor = forwardRef((props, ref) => {
    //console.log('props', props);
    
    const [height, setHeight] = useState(MIN_HEIGHT);

    const getHeight = (value) => {
        const countOfLines = value?.split("\n").length ?? 1; //console.log('value, countOfLines', value, countOfLines);
        if (countOfLines >= MIN_COUNT_OF_LINES) {
            const currentHeight = countOfLines * LINE_HEIGHT; //console.log('currentHeight', currentHeight);
            if (MAX_HEIGHT > currentHeight + 20) {
                return currentHeight + 20;
            }
            return MAX_HEIGHT;
        }

        return MIN_HEIGHT;
    }

    useEffect(() => {
        const currentHeight = getHeight(props.value);
        //console.log('Old height for ' + props.id, height, 'Will resize into', currentHeight);//, props.value);
        if (!height || currentHeight > height) {
            setHeight(currentHeight);
        }
    }, [props.value, props.id, height])

    const options = {
        //readonly: true,
        automaticLayout: true,
        minimap: { enabled: false },
        scrollbar: {
            handleMouseWheel: true,
            vertical: 'hidden',
            horizontal: 'hidden',
            verticalScrollbarSize: 0,
            horizontalScrollbarSize: 0,
            arrowSize: 0
        }
    };
    
    return (
        <div style={{ height: height }}>
            <Editor {...props}
                width="580px"
                height="100%"
                defaultLanguage="lua"
                theme={props.disabled ? "vs-dark" : "light"}
                options={options}
            />
        </div>
    )
});
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import StorageLayout from './StorageLayout';
import { Section, Action, EntityType, Role, PublishingRole } from '../../helpers/enums';
import { ParameterColumns, ParameterLogColumns, ParameterColumnsUserAndDateCombined } from './Columns';
import EntityLayout from '../common/EntityLayout';
import { hasContributorRole, hasRoleForStorageAndFactory, hasPublishingRole } from '../../helpers/commonHelpers';

export function Parameters({ msalInstance }){

    const [enabledActions, setEnabledActions] = useState([]);

    let selectedStorage = useSelector(state => state.workspace.activeItem);
    let currentUser = useSelector(state => state.application.user);
    let selectedFactories = useSelector(state => state.workspace.selectedFactories);
    const selectedStorageId = selectedStorage ? selectedStorage.id : 0;

    const setValues = (entity, newValues, action) => {
        if (action && (action === Action.EDIT || action === Action.PUBLISH)) {
            entity.allReferences = newValues.allReferences;
            entity.code = newValues.code;
            entity.comments = newValues.comments;
            entity.description = newValues.description;
            entity.factory = newValues.factory;
            entity.factoryId = newValues.factoryId;
            entity.factorySymbol = newValues.factorySymbol;
            entity.hasUnit = newValues.hasUnit;
            entity.internalId = newValues.internalId;
            entity.internalNotes = newValues.internalNotes;
            entity.prodStatus = newValues.prodStatus;
            entity.references = newValues.references;
            entity.revisionId = newValues.revisionId;
            entity.revisions = newValues.revisions;
            entity.stageStatus = newValues.stageStatus;
            entity.type = newValues.type;
            entity.unit = newValues.unit;
            entity.unitId = newValues.unitId;
            entity.updatedBy = newValues.updatedBy;
            entity.updatedDate = newValues.updatedDate;
            entity.value = newValues.value;
        }
        entity.disabledSelect = !hasRoleForStorageAndFactory(currentUser, Role.CONTRIBUTOR, selectedStorageId, newValues.factoryId);
    }

    useEffect(() => {
        if (currentUser) {
            const selectedStorageId = selectedStorage ? selectedStorage.id : 0;
            let userActions = [{ action: Action.VIEW }, { action: Action.SETTINGS }, { action: Action.LOG }];
            if (hasContributorRole(currentUser, selectedStorageId, selectedFactories)) {
                userActions.push({
                    action: Action.CREATE,
                    disabled: selectedFactories.length === 0
                });
                userActions.push({
                    action: Action.EDIT,
                });
                userActions.push({
                    action: Action.COPY,
                });
                userActions.push({
                    action: Action.DELETE,
                });
                if (hasPublishingRole(currentUser, PublishingRole.STAGE, selectedStorageId, selectedFactories, false) ||
                    hasPublishingRole(currentUser, PublishingRole.PROD, selectedStorageId, selectedFactories, false)) {
                    userActions.push({
                        action: Action.PUBLISH,
                    });
                }
            }
            setEnabledActions(userActions);
        }
    }, [currentUser, selectedStorage, selectedFactories]);

    return (
        <StorageLayout
            title="Manage parameters"
            showFactoryList={true}
            msalInstance={msalInstance}
        >
            <div className="container-fluid mt-2 mb-4 ms-2 content">
                <b>Parameters</b> are useful when multiple elements depend on the same value. If the value changes, rather than updating each element individually, you only need to update the single parameter.
                <br />Use syntax <mark>GlobalParam(&quot;CODE&quot;)</mark>, <mark>GlobalParamOrNil(&quot;CODE&quot;)</mark>, <mark>LocalParam(&quot;CODE&quot;)</mark> or <mark>LocalParamOrNil(&quot;CODE&quot;)</mark> to refer to global and local parameters. These functions can be used in <b>elements</b> and <b>value types</b>.

            </div>
            <EntityLayout
                type={Section.WORKSPACE}
                entityType={EntityType.PARAMETER}
                columns={ParameterColumns()}
                setValues={setValues}
                enabledActions={enabledActions}
                logColumns={ParameterLogColumns()}
                combinedColumns={ParameterColumnsUserAndDateCombined()}
                msalInstance={msalInstance}
            />
        </StorageLayout>
    );
}
import React from 'react';
import ItemModal from './ItemModal';
import ElementModal from './ElementModal';
import ParameterModal from './ParameterModal';
import CategoryModal from './CategoryModal';
import ValueTypeModal from './ValueTypeModal';
import ClientInterfaceModal from './ClientInterfaceModal';
import UnitModal from './UnitModal';
import ElementTypeModal from './ElementTypeModal';
import UnitGroupModal from './UnitGroupModal';
import { EntityType, Runtime, RuntimeTexts } from '../../../helpers/enums';
import { Error } from '../../common/Error';

export default function EntityLayoutModal({
    entityType,
    showModal,
    toggleModal,
    modalStatus,
    selectedEntity,
    filters,
    entityIsCreated,
    entityIsUpdated,
    runtime,
    msalInstance
}) {
    const runtimeText = runtime === Runtime.JS ? RuntimeTexts.JS : RuntimeTexts.LUA;
    switch (entityType) {
        case EntityType.ITEM:
            return (
                <ItemModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedItem={selectedEntity}
                    filters={filters}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    msalInstance={msalInstance}
                />
            );
        case EntityType.ELEMENT:
            return (
                <ElementModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedElement={selectedEntity}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    runtimeText={runtimeText}
                    msalInstance={msalInstance}
                />
            );
        case EntityType.PARAMETER:
            return (
                <ParameterModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedParameter={selectedEntity}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    msalInstance={msalInstance}
                />
            );
        case EntityType.CATEGORY:
            return (
                <CategoryModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedCategory={selectedEntity}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    msalInstance={msalInstance}
                />
            );
        case EntityType.VALUETYPE:
            return (
                <ValueTypeModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedValueType={selectedEntity}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    runtimeText={runtimeText}
                    msalInstance={msalInstance}
                />
            );
        case EntityType.CLIENTINTERFACE:
            return (
                <ClientInterfaceModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedClientInput={selectedEntity}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    msalInstance={msalInstance}
                />
            );
        case EntityType.UNIT:
            return (
                <UnitModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedUnit={selectedEntity}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    msalInstance={msalInstance}
                />
            );
        case EntityType.ELEMENTTYPE:
            return (
                <ElementTypeModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedElementType={selectedEntity}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    msalInstance={msalInstance}
                />
            );
        case EntityType.UNITGROUP:
            return (
                <UnitGroupModal
                    isOpen={showModal}
                    toggle={toggleModal}
                    status={modalStatus}
                    selectedUnitGroup={selectedEntity}
                    onEntityCreated={entityIsCreated}
                    onEntityUpdated={entityIsUpdated}
                    msalInstance={msalInstance}
                />
            )
        default:
            return (
                <Error msg={"No modal set for " + entityType + "!"} />
            );
    };
}
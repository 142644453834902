import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, ButtonToolbar } from 'reactstrap';
import { callAPI } from '../../helpers/api';
import { UserColumns } from './Columns';
import { NotAuthorized } from '../common/NotAuthorized';
import AccessRightsModal from './modals/AccessRightsModal';
import { isAdmin } from '../../helpers/commonHelpers';
import DeleteModal from '../common/modals/DeleteModal';
import { UserStatus } from '../../helpers/enums';
import SimpleCustomTable from '../common/SimpleCustomTable';
import adminStorageActions from '../../actions/adminStorageActions';
import TableFiltersAndButtons from '../common/TableFiltersAndButtons';
import applicationActions from '../../actions/applicationActions';
import { getActionsColumnDef } from '../../helpers/tableHelpers';

export default function AccessRightsForm({
    populate,
    msalInstance
}) {
    const userColumns = UserColumns();
    
    const [users, setUsers] = useState(() => []);
    const [editUsers, setEditUsers] = useState([]);
    const [deleteUsers, setDeleteUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [loading, setIsLoading] = useState(true);
    const [searchError, setSearchError] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const dispatch = useDispatch();

    let storageLoaded = useSelector(state => state.adminStorages.loaded);
    let currentUser = useSelector(state => state.application.user);

    const populateUsers = async () => {
        setIsLoading(true);
        let result = await callAPI("user", msalInstance, currentUser.authUser);
        var userResult = result.json;
        if (result.errorMsg && !userResult) {
            setSearchError(result.errorMsg);
            setUsers([]);
            dispatch(applicationActions.setUserUnauthorized(result.unAuthorized ?? false));
        }
        else {
            setUsers(userResult);
            if (userResult.length === 0) {
                setSearchError('No users found!')
            }
            else {
                setSearchError(null);
            }
        }
        setIsLoading(false);
    }

    async function populateStorages() {
        //console.log('will populate storages');
        let result = await callAPI('storage', msalInstance, currentUser.authUser);
        if (result.errorMsg && !result.json) {
            dispatch(adminStorageActions.setStorages([]));
        }
        else {
            var storageList = result.json;
            dispatch(adminStorageActions.setStorages(storageList));
            if (storageList.length > 0) {
                // Set the first storage active
                dispatch(adminStorageActions.setActiveStorage(storageList[0]));
            }
        }
    }

    useEffect(() => {
        if (populate && loading && (!users || users.length === 0) && !searchError) {
            populateUsers();
        }
    }, [populate, loading, users, searchError]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!storageLoaded) {
            populateStorages();
        }
    }, [storageLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggleModal = (ids) => {
        //console.log('toggle modal', ids);
        const modalIsAboutToOpen = !showModal;

        if (ids?.length > 0 && modalIsAboutToOpen) {
            setEditUsers(users.filter(u => ids.includes(u.id)));
        }
        setShowModal(modalIsAboutToOpen);
    }

    const toggleDeleteModal = (ids) => {
        const modalIsAboutToOpen = !showDeleteModal;

        if (ids?.length > 0 && modalIsAboutToOpen) {
            setDeleteUsers(users.filter(u => ids.includes(u.id)));
        }
        setShowDeleteModal(modalIsAboutToOpen);
    }

    const setUserDataWithinList = (list, newValues) => {
        var index = list.findIndex(i => i.id === newValues.id);
        if (index >= 0) {
            list[index].status = newValues.status;
            list[index].storageOwnerIds = newValues.storageOwnerIds;
            list[index].roles = newValues.roles;
        }
    }

    const usersDisabled = (userIds) => {
        if (userIds && userIds.length > 0) {
            var newUsersList = users;
            userIds.forEach(userId => {
                setUserDataWithinList(newUsersList,
                    {
                        id: userId,
                        status: UserStatus.DISABLED,
                        roles: ""
                    });
            });
            setUsers([...newUsersList]);
        }
    }

    const usersUpdated = (updatedUsers) => {
        //console.log('updatedUsers', updatedUsers);
        if (updatedUsers?.length > 0) {
            var newUsersList = users;
            updatedUsers.forEach(user => {
                setUserDataWithinList(newUsersList, user);
            }); console.log('newUsersList', newUsersList);
            setUsers([...newUsersList]);
        }
    }

    const cellFn = (original) => {
        return (
            <ButtonToolbar>
                <Button
                    color="primary"
                    className="me-1 btn-xs"
                    title="Edit"
                    onClick={() => toggleModal([original.id])}
                >
                    <img className="icon" src="./icons/button_edit.svg" alt=""/>
                </Button>
                <Button
                    color="danger"
                    className="me-1 btn-xs"
                    title="Disable"
                    onClick={() => toggleDeleteModal([original.id])}
                    disabled={original.status === UserStatus.DISABLED}
                >
                    <img className="icon" src="./icons/button_delete.svg" alt=""/>
                </Button>
            </ButtonToolbar>
        );
    }

    const columnsWithActions = [...userColumns, getActionsColumnDef(cellFn)];

    const buttons = [{
        color: "primary",
        onClick: toggleModal,
        icon: "./icons/button_edit.svg",
        title: "Edit selected",
        disabled: !(selectedUsers?.length > 0)
    }, {
        color: "danger",
        onClick: toggleDeleteModal,
        icon: "./icons/button_delete.svg",
        title: "Disable selected",
        disabled: !(selectedUsers?.length > 0)
    }];

    if (!isAdmin(currentUser)) {
        return (<NotAuthorized />);
    }

    return (
        <React.Fragment>
            <TableFiltersAndButtons
                title="Permissions"
                data={users}
                selectedData={selectedUsers}
                columns={columnsWithActions}
                setSelectedData={setSelectedUsers}
                loading={loading}
                msg={searchError}
                localStorageKey="UserPermissionsTable"
                buttons={buttons}
                pageSizes={[10, 20, 30]}
                tableClass="table-with-states"
            />
            <AccessRightsModal
                isOpen={showModal}
                toggle={toggleModal}
                selectedUsers={editUsers}
                onUsersUpdated={usersUpdated}
                msalInstance={msalInstance}
                currentUser={currentUser.authUser}
            />
            <DeleteModal
                isOpen={showDeleteModal}
                toggle={toggleDeleteModal}
                entityProperties={{
                    baseUrl: "user/delete/",
                    single: "user",
                    plural: "users",
                    deleteModalClass: "modal-lg"
                }}
                deleteIds={deleteUsers.map(i => i.id)}
                onDeleted={usersDisabled}
                user={currentUser.authUser}
                msalInstance={msalInstance}
                title="Disable">
                <p>Are you sure you want to disable users</p>
                <SimpleCustomTable
                    tableColumns={userColumns}
                    tableData={deleteUsers}
                    className="table-with-states"
                />
            </DeleteModal>
        </React.Fragment>
    );
}
import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import EntityModalBase from './EntityModalBase';
import { Section, Action, ConnectionType, UserInputType, RevisionStatus, UnitType, Role } from '../../../helpers/enums';
import { useSelector } from 'react-redux';
import { CustomSelect, SelectWithStyles } from '../../common/CustomSelect';
import { UserInputColumns } from '../Columns';
import CustomTableWithEditableCells from '../../common/CustomTableWithEditableCells';
import { validateFormulaFromServer,  
    getUnitOptions, getTypeById } from '..';
import {
    parseToNumber, getFactoryOptions, getOption, getSelectedOption, INIT_ELEMENT_1,
    INIT_ELEMENT_2, INIT_ELEMENT_3, VALUE_NOT_CALCULABLE
} from '../../../helpers/commonHelpers';
import TextareaAutosize from 'react-textarea-autosize';
import LabelWithTooltip from '../../common/LabelWithTooltip';
import { CustomSimpleEditor } from '../../common/CustomEditor';
import { CalculatedValueInput } from '../../common/CalculatedValueInput';

const getAvailableValueTypeOptions = (valueTypes, factoryId, globalFactoryId, selectedElementType) => {
    //console.log('storage value types, fetching for factory "' + factoryId + '"', valueTypes, selectedElementType);
    if (factoryId && factoryId > 0){
        if (valueTypes && valueTypes.length > 0){
            if (selectedElementType && selectedElementType.valueTypes && selectedElementType.valueTypes.length > 0){
                const supportedValueTypes = selectedElementType.valueTypes.map(vt => vt.id);//console.log('supportedValueTypes', supportedValueTypes);
                return valueTypes.filter(v => (v.supportsAllFactories || v.supportedFactories.some(f => f.id === factoryId || f.id === globalFactoryId))
                    && supportedValueTypes.includes(v.id))
                    .map(vt => { return getOption(vt.id, vt.code, vt.description)});
            }
            return valueTypes.filter(v => v.supportsAllFactories || v.supportedFactories.some(f => f.id === factoryId || f.id === globalFactoryId))
                .map(vt => { return getOption(vt.id, vt.code, vt.description)});
        }
    }

    // No factory selected yet - let's return all value types that supports all factories
    if (valueTypes && valueTypes.length > 0){
        if (selectedElementType && selectedElementType.valueTypes && selectedElementType.valueTypes.length > 0){
            const supportedValueTypes = selectedElementType.valueTypes.map(vt => vt.id);
            return valueTypes.filter(v => (v.supportsAllFactories || v.supportedFactories.some(f => f.id === globalFactoryId)) && supportedValueTypes.includes(v.id))
                .map(vt => { return getOption(vt.id, vt.code, vt.description)});
        }
        return valueTypes.filter(v => v.supportsAllFactories || v.supportedFactories.some(f => f.id === globalFactoryId))
            .map(vt => { return getOption(vt.id, vt.code, vt.description) });
    }

    return [];
}

const getSelectedItem = (selectedItems, selectedElement) => {
    //console.log('selectedItems', selectedItems); console.log('selectedElement', selectedElement);
    if (selectedItems?.length > 0){
        if (selectedElement?.itemId){
            const item = selectedItems.filter(i => i.id === selectedElement.itemId);
            if (item?.length > 0){
                return item[0];
            }

            return selectedItems[0];
        }
        else if (selectedItems.length === 1){
            return selectedItems[0];
        }
        return {};//selectedItems[0];
    }
    return {};
}

const getSelectedElementTypeOption = (elementTypeOptions, selectedElement) => {
    //console.log('elementTypeOptions, selectedElement', elementTypeOptions, selectedElement);
    if (!elementTypeOptions || elementTypeOptions.length === 0){
        return [];
    }

    if (elementTypeOptions.length === 1){
        return elementTypeOptions[0];
    }

    if (selectedElement?.isConfigElement) {
        const selectedConfigOption = elementTypeOptions.filter(o => o.label === selectedElement.code);
        if (selectedConfigOption?.length > 0) {
            return selectedConfigOption[0];
        }
        return elementTypeOptions[0];
    }

    const elementTypeId = selectedElement ? selectedElement.elementTypeId : null;

    if (selectedElement && !elementTypeId){
        return getCustomElementTypeOption();
    }

    if (!elementTypeId){
        return [];
    }

    const option = elementTypeOptions.filter(o => o.value === elementTypeId);
    if (option?.length > 0){
        return option[0];
    }
    return [];
}

const getSelectedElementType = (selectedOption, elementTypes) => {
    //console.log('selectedOption, elementTypes', selectedOption, elementTypes);
    if (selectedOption?.isConfig) {
        return selectedOption ?? getInitElementTypeOption();
    }
    const customOption = getCustomElementTypeOption();
    //console.log('getSelectedElementType: selectedOption, customOption, elementTypes', selectedOption, customOption, elementTypes);
    if (selectedOption && customOption && selectedOption.value === customOption.value) {
        return {id: selectedOption.value, isConfig: selectedOption.isConfig ?? false};
    }

    if (!selectedOption ||
        (!elementTypes || elementTypes.length === 0)) {
        return null;
    }

    const selectedElementType = elementTypes.filter(type => type.id === selectedOption.value);
    if (selectedElementType && selectedElementType.length > 0){
        return selectedElementType[0];
    }

    return null;
}

const isUnitRequired = (valueType) => {
    //console.log('valueType', valueType);
    if (!valueType || !valueType.unitType){
        return false;
    }
    
    if (valueType.unitType === UnitType.NONE || valueType.unitType === UnitType.OPTIONAL){
        return false;
    }

    return true;
}

const getCustomElementTypeOption = () => {
    return { value: 0, label: "Custom" };
}

const getInitElementTypeOption = () => {
    return getInitElementTypeOptions()[0];
}

const getInitElementTypeOptions = () => {
    return [{ value: 1, label: INIT_ELEMENT_1, subLabel: 'Runs first', isConfig: true },
        { value: 2, label: INIT_ELEMENT_2, subLabel: 'Runs second', isConfig: true },
        { value: 3, label: INIT_ELEMENT_3, subLabel: 'Runs third', isConfig: true }];
}

const getAvailableElementTypeOptions = (selectedElementTypes, selectedElementTypeIds, isConfigElement) => {
    if (isConfigElement) {
        return getInitElementTypeOptions();
    }
    let elementTypeOptions = selectedElementTypes?.length > 0 ?
            selectedElementTypes.map(et => { return getOption(et.id, et.code, et.description) }) : [];
    // Add default element type option 
    const customElementTypeOption = getCustomElementTypeOption();
    if (selectedElementTypeIds.includes(customElementTypeOption.value)) {
        elementTypeOptions.push(customElementTypeOption);
    }
    return elementTypeOptions;
}

const schema = yup.object().shape({
    item: yup.object().label('Item').shape({ value: yup.number().required('Select an Item') }).nullable().required('Select an Item'),
    factory: yup.object().shape({value:yup.number().required('Select a Factory')}).nullable().required('Select a Factory'),
    elementType: yup.object().shape({value:yup.number().required('Select an Element code')}).nullable().required('Select an Element code'),
    code: yup.string().label('Code').required().max(50),
    criterion: yup.string().when('connectionType', {
        is: 1,
        then: yup.string().required('Criterion is a required field when Criterion selected as connection type')
    }),
    valueType: yup.object().shape({value:yup.number().required('Select a Type')}).nullable().required('Select a Type'),
    input: yup.string().label('Input')
        .when('isGlobal', {
            is: true,
            then: yup.string().required('Input is required')
        })
        .when('inputDefinedLater', {
            is: true,
            then: yup.string().required()
        }),
    unit: yup.object().shape({ value: yup.number().nullable()}).nullable()
        .when('unitRequired', {
            is: true,
            then: yup.object().shape({ value: yup.number().required('Select a Unit') }).nullable().required('Select a Unit')
        }),
    internalId: yup.string().label('Internal ID').max(255),
    internalNotes: yup.string().label('Internal notes').max(1000),
    comments: yup.string().label('Comments').max(1000)
        .when('notFirstVersion', {
            is: true,
            then: yup.string().required()
        })
});

function ElementModal({
    isOpen,
    toggle,
    status,
    selectedElement,
    onEntityCreated,
    onEntityUpdated,
    runtimeText,
    msalInstance
}){
    const stateProperty = Section.WORKSPACE;

    const createNewRevision = status === Action.EDIT && selectedElement && selectedElement.id > 0 
        && (selectedElement.stageStatus === RevisionStatus.LATEST_REVISION || selectedElement.prodStatus === RevisionStatus.LATEST_REVISION)
        ? true : false;
    const notFirstVersion = status === Action.EDIT && selectedElement.id > 0
        && (selectedElement.stageStatus > RevisionStatus.NO_REVISION || selectedElement.prodStatus > RevisionStatus.NO_REVISION);
    //console.log('status, createNewRevision, notFirstVersion', status, createNewRevision, notFirstVersion, selectedElement);
    const currentUser = useSelector(state => state.application.user);
    const user = currentUser ? currentUser.authUser : {};
    const selectedStorage = useSelector(state => state[stateProperty].activeItem);
    const selectedItems = useSelector(state => state[stateProperty].selectedItems);
    const selectedItem = getSelectedItem(selectedItems, selectedElement);
    const factories = useSelector(state => state[stateProperty].factories);
    const selectedFactoryIds = useSelector(state => state[stateProperty].selectedFactories);
    const selectedFactories = factories ? factories.filter(f => selectedFactoryIds.includes(f.id)) : [];
    const globalFactory = factories ? factories.filter(f => f.isGlobal)[0] : {};
    const valueTypes = useSelector(state => state[stateProperty].storageValueTypes); //console.log('valueTypes', valueTypes);
    const parameters = useSelector(state => state[stateProperty].storageParameters);
    const units = useSelector(state => state[stateProperty].storageUnits);
    const unitOptions = getUnitOptions(units);
    const factoryOptions = getFactoryOptions(selectedFactories, status === Action.VIEW ? Role.READER : Role.CONTRIBUTOR);
    const selectedElementTypeIds = useSelector(state => state[stateProperty].selectedElementTypes);
    const elementTypes = useSelector(state => state[stateProperty].storageElementTypes);

    const selectedElementTypes = elementTypes ? elementTypes.filter(e => selectedElementTypeIds.includes(e.id)) : [];
    const customElementTypeOption = getCustomElementTypeOption();

    const [criterion, setCriterion] = useState("");
    //const [criterionChanged, setCriterionChanged] = useState(false); // for monaco editor
    const [criterionHelpText, setCriterionHelpText] = useState("");
    const [userInputData, setUserInputData] = useState([]);
    const [selectedElementType, setSelectedElementType] = useState(null);
    const [selectedValueType, setSelectedValueType] = useState({});
    const [inputHelpText, setInputHelpText] = useState("");
    const [calculatedValue, setCalculatedValue] = useState("");
    const [manualCalculatedValueError, setManualCalculatedValueError] = useState("");
    const [elementTypeOptions, setElementTypeOptions] = useState([]);
    const [valueTypeOptions, setValueTypeOptions] = useState([]);
    const [globalFactorySelected, setGlobalFactorySelected] = useState(selectedElement && globalFactory && selectedElement.factoryId === globalFactory?.id ? true : false);
    const [criterionSelected, setCriterionSelected] = useState(selectedElement && selectedElement.connectionType === 1);
    const [unitRequired, setUnitRequired] = useState(isUnitRequired(selectedElement?.valueType));
    const [filteredUnitOptions, setFilteredUnitOptions] = useState([]);
    const [elementCodeHidden, setElementCodeHidden] = useState(true);
    const [elementSectionDisabled, setElementSectionDisabled] = useState(selectedElement || selectedFactories.length === 1 || factoryOptions.length === 1 ? false : true);
    const [isSubstitutable, setIsSubstitutable] = useState(false);
    const [connectionTypeId, setConnectionTypeId] = useState(0);
    const [showInput, setShowInput] = useState(true);
    const [inputValue, setInputValue] = useState("");
    //const [inputChanged, setInputChanged] = useState(false); // monaco editor
    const [isConfigElement, setIsConfigElement] = useState(false);

    const { handleSubmit, reset, clearErrors, setError, formState: {errors, isValid}, control, getValues, setValue, trigger} = useForm({resolver: yupResolver(schema), mode: "onChange"});
    //console.log('errors', errors);
    let defaultValues = {
        item: selectedItem?.id ? { value: selectedItem.id, label: selectedItem.code} : [],
        description: selectedItem?.description ?? "",
        category: selectedItem?.category ?? "",
        id: 0,
        factory: factoryOptions.length === 1 ? factoryOptions[0] : [],
        globalFactoryId: globalFactory?.id ?? 0,
        elementType: [],//selectedElementTypeOption,
        code: "",
        isGlobal: true,
        inputDefinedLater: false,
        baseType: -1,
        valueType: [],
        //input: "",
        calculatedValue: "",
        unitType: 0,
        unit: [],
        internalNotes: "",
        internalId: "",
        comments: "",
        notFirstVersion: notFirstVersion
    };

    const getValueTypeErrorMsg = (selectedFactory, selectedElementType) => {
        const factories = selectedFactory ? selectedFactories.filter(f => f.id === selectedFactory.value) : [];
        const factoryCode = factories.length > 0 ? factories[0].code : "";
        if (selectedFactory && selectedElementType && selectedElementType.code){
            return "The selected factory " + factoryCode + " together with selected element code " + selectedElementType.code 
                + " do not support the selected value type. Please choose another one!"
        }

        if (selectedFactory){
            return "The selected factory " + factoryCode + " does not support the selected value type. Please choose another one!"
        }
        
        if (selectedElementType && selectedElementType.code){
            return "The selected element code " + selectedElementType.code 
                + " does not support the selected value type. Please choose another one!"
        }
        
        return "Selected value type is not supported. Please choose another one!";
    }
    
    const getData = (data) => {
        if (manualCalculatedValueError.length > 0) {
            setError("calculatedValue", {
                type: "manual",
                message: manualCalculatedValueError
            });
        }
        // Check if the selected value type is available within selected factory.
        // It can happen that user changes the factory when value type is already selected.
        if (!valueTypeOptions.some(o => o.value === data.valueType.value)) {
            setError("valueType", {
                type: "manual",
                message: getValueTypeErrorMsg(data.factory, selectedElementType)
            });
        } //console.log('isConfigElement, data.elementType', isConfigElement, data.elementType);
        return {
            id: data.id,
            code: data.code,
            storageId: data.storageId,
            isSubstitutable: data.isSubstitutable,
            connectionType: data.connectionType,
            criterion: data.criterion,
            valueTypeId: data.valueType.value,
            valueTypeFormula: selectedValueType ? selectedValueType.formula : "",
            userInputs: userInputData.length > 0 ? userInputData.map(i => { return { id: i.id, value: i.value?.toString() ?? "" } }) : [],
            input: data.input,
            unitId: data.unit ? data.unit.value : null,
            internalNotes: data.internalNotes,
            internalId: data.internalId,
            comments: data.comments,
            factoryId: data.factory.value,
            itemId: data.item.value,
            elementTypeId: isConfigElement ? 0 : data.elementType.value
        }
    }
    
    const validateCriterion = async () => {
        //console.log('criterion', criterion);//console.log('clientInputs', clientInputs);
        const selectedFactory = getValues('factory');
        clearErrors("criterion");
        let result = await validateFormulaFromServer(
            criterion,
            null,
            selectedStorage,
            selectedFactory,
            "Criterion",
            null,
            null,
            null,
            msalInstance,
            user
        );//console.log('result', result);
        if (result.errorMsg && result.errorMsg.length > 0){
            setCriterionHelpText("");
            setError("criterion", {
                type:"manual",
                message: result.errorMsg
            });
        }

        if (result.value || result.validationIsNull || result.noValue){
            setCriterionHelpText("Criterion is valid!");
        }

        //setCriterionChanged(false);// for monaco editor
    }

    const updateCellData = async (rowIndex, columnId, newValue) => {
        // We also turn on the flag to not reset the page
        //console.log('newValue, columnId, rowIndex', newValue, columnId, rowIndex, userInputData);
        //setSkipPageReset(true);
        clearErrors("calculatedValue");
        var newUserInputs = userInputData.map((input, index) => {
            if (index === rowIndex) {
                return { ...input, value: newValue }
            }
            return { ...input }
        });
        setUserInputData(newUserInputs);
        if (selectedValueType.formula){
            validateFormula(selectedValueType.formula, newUserInputs, false);
        }
    }

    const getUserInputsWithDefaultValues = (inputs) => {
        if (inputs?.length > 0) {
            return inputs.map(input => {
                return {
                    ...input,
                    value: input.typeId === UserInputType.BOOLEAN ? false : input.typeId === UserInputType.STRING ? "" : "0"
                };
            });
        }
        return [];
    }

    const validateInput = async () => {
        //console.log('validate input', inputValue);
        clearErrors("calculatedValue");
        await validateFormula(inputValue);
        //setInputChanged(false);// for monaco editor
    }

    const validateInputOrValueTypeFormulaAndSetValue = async (valueType, inputsWithDefaultValues = true) => {
        //console.log('validateInputOrValueTypeFormulaAndSetValue', valueType, userInputData);
        // Validate input
        if (!errors || !errors.valueType) {
            const input = getValues('input'); //console.log('input', input);
            if (input && input.length > 0) {
                await validateFormula(input, userInputData);
            }
            else {
                if (valueType?.formula) {
                    //console.log('value type has formula', valueType.formula);
                    await validateFormula(valueType.formula,
                        inputsWithDefaultValues ? getUserInputsWithDefaultValues(valueType.userInputs) : userInputData,
                        false);
                }
                else {
                    setCalculatedValue("");
                    setValue('calculatedValue', "");
                }
            }
            trigger('input');
        }
    }

    const validateFormula = async (formula, userInputs, isInEditMode = true) => {
        //console.log('Checking formula!');
        clearErrors("calculatedValue");
        setManualCalculatedValueError("");
        setInputHelpText("");
        let selectedFactory = getValues('factory');
        if (!selectedFactory) {
            if (factoryOptions.length === 1) {
                selectedFactory = factoryOptions[0];
            }
        }
        let result = await validateFormulaFromServer(
            formula,
            userInputs,
            selectedStorage,
            selectedFactory,
            "Input",
            isConfigElement ? false : getValues("isGlobal") || getValues("inputDefinedLater"), // For config elements let's not check the type
            getValues("baseType"),
            isInEditMode,
            msalInstance,
            user
        ); //console.log('scripting result', result);
        if (result?.value || result?.noValue || result?.value === 0) {
            setCalculatedValue(result.value); //console.log('value found', result.value);
            setInputHelpText("Input is valid!");
        }
        else{
            setCalculatedValue(""); //console.log("Let's empty value!");
            setValue('calculatedValue', "");
        }
        if (result.validationIsNull) {
            setCalculatedValue(" ");
            setValue('calculatedValue', " ");
            setInputHelpText("Formula is valid. However the validation returned null!");
        }
        trigger("calculatedValue");
        if (result.errorMsg && result.errorMsg.length > 0){
            setManualCalculatedValueError(result.errorMsg);
            setInputHelpText("");
        }
    }

    const shouldInputFieldBeShown = (valueType) => {
        if (!valueType || !valueType.id || valueType.id === 0){
            return true;
        }

        if (valueType.isGlobal || valueType.inputDefinedLater){
            return true;
        }

        if (!valueType.userInputs || valueType.userInputs.length === 0){
            return false;
        }

        return true;
    }

    const handleItemChange = (selectedOption) => {
        //console.log('item changed, selectedOption', selectedOption);
        if (selectedOption){
            const selectedItem = selectedItems.filter(i => i.id === selectedOption.value)[0]; //console.log('selectedItem', selectedItem);
            const isConfigItem = selectedItem?.isConfig ?? false;
            setIsConfigElement(isConfigItem);
            setValue('item', selectedOption);
            setValue('description', selectedItem.description);
            setValue('category', selectedItem.category ?? "");
            const elTypeOptions = getAvailableElementTypeOptions(selectedElementTypes, selectedElementTypeIds, isConfigItem);
            setElementTypeOptions(elTypeOptions); //console.log('elTypeOptions', elTypeOptions);
            if (isConfigItem) {
                const vtOptions = setElementTypeAndGetAvailableValuetypeOptions(getInitElementTypeOption()); //console.log('vtOptions', vtOptions);
                setValueTypeFromOption(vtOptions.length >= 1 ? vtOptions[0] : []);
            }
        }
        else {
            setIsConfigElement(false);
            setValue('item', []);
            setValue('description', "");
            setValue('category', "");
        }
        trigger('item');
        trigger('code');
    }

    const setFactory = (option) => {
        const selectedFactoryId = option && option.value ? option.value : -1;
        setValue('factory', option);
        setElementSectionDisabled(selectedFactoryId > 0 ? false : true); // Element section is disabled if no factory was selected
        const globalFactorySelected = globalFactory ? selectedFactoryId === globalFactory?.id : false;
        setGlobalFactorySelected(globalFactorySelected);
        if (!globalFactorySelected) {
            // we need to set substitutable property as 'No' since the property is only available for Global factory
            setIsSubstitutable(false);
            setValue('isSubstitutable', false);
        }
    }

    const handleFactoryChange = (selectedOption) => {
        //console.log('handleFactoryChange selectedOption', selectedOption);
        //console.log('elementTypeOptions & selectedElementType', elementTypeOptions, selectedElementType);
        clearErrors();
        setFactory(selectedOption);
        const current = {
            code: getValues('code'),
            elementType: getValues('elementType'),
            valueType: getValues('valueType'),
            input: getValues('input')
        }; //console.log('current code, el type & type', current);
        const keepExistingElementType = current.elementType && elementTypeOptions.some(t => t.value === current.elementType?.value);
        const selectedElementTypeOption = elementTypeOptions.length === 1 ? elementTypeOptions[0] :
            keepExistingElementType ? current.elementType : [];
        //console.log('selectedElementTypeOption & keepExistingElementType', selectedElementTypeOption, keepExistingElementType);
        const valueTypeOptions = setElementTypeAndGetAvailableValuetypeOptions(selectedElementTypeOption);
        const keepExistingValueType = keepExistingElementType && valueTypeOptions.some(v => v.value === current.valueType?.value);

        trigger('factory'); // Validate factory

        // Should we set reset input and selected value type?
        if (!keepExistingElementType) {
            //console.log('reset input value');
            setInputValue("");
            setValue('input', "");
        }
        else {
            setValue('code', current.code);
        }
        if (!keepExistingValueType) {
            const vt = setValueTypeFromOption(valueTypeOptions && valueTypeOptions.length === 1 ? valueTypeOptions[0] : []);
            // Validate input
            validateInputOrValueTypeFormulaAndSetValue(vt);
        }
        else {
            // Let's validate the current value type since the factory change can effect the element value
            validateInputOrValueTypeFormulaAndSetValue(valueTypes.find(v => v.id === current.valueType.value), false);
        }
    }

    const handleIsSubstitutableChange = (e) => {
        const isChecked = e.target.checked;
        setValue('isSubstitutable', isChecked);
        setIsSubstitutable(isChecked);
    }

    const handleConnectionTypeChange = (e) => { //console.log('handleConnectionTypeChange', e.target.value, typeof e.target.value);
        const value = parseToNumber(e.target.value);//console.log('value & type', value, typeof value);
        if (e.target.value){
            setCriterionSelected(value === 1);
            setValue('connectionType', value);
            setConnectionTypeId(value);
            if (value === 0){
                setValue('criterion', ""); // criterion field must be emptied
            }
        }
        else{
            setCriterionSelected(false);
            setValue('connectionType', 0);
            setConnectionTypeId(0);
        }
    }

    const setElementType = (selectedOption) => {
        clearErrors('elementType'); //console.log('ElementType: selectedOption && selectedItem', selectedOption, selectedItem);
        setElementCodeHidden(true);

        let option = selectedOption?.value ? selectedOption :
            elementTypeOptions && elementTypeOptions.length === 0 ? elementTypeOptions[0] : selectedOption;

        if (option?.value >= 0) {
            setValue('elementType', option);
            const newSelectedElementType = getSelectedElementType(option, elementTypes);//console.log('newSelectedElementType',newSelectedElementType);
            setSelectedElementType(newSelectedElementType);
            if (option.value === customElementTypeOption.value) {
                setElementCodeHidden(false); // The code field should be enabled
                setValue('code', status === Action.CREATE ? "" : defaultValues.code ?? "");
            }
            else {
                setValue('code', newSelectedElementType?.code ?? newSelectedElementType?.label ?? "");
            }
            //trigger('code'); console.log('getValues & errors:', getValues(), errors);
            return newSelectedElementType;
        }
        else {
            setValue('elementType', []);
            setSelectedElementType(null);
            setValue('code', defaultValues.code);
        }
        return {};
    }

    const setElementTypeAndGetAvailableValuetypeOptions = (selectedOption) => {
        setInputHelpText("");
        const selectedFactoryOption = getValues('factory');
        const selectedFactoryId = selectedFactoryOption ? selectedFactoryOption.value : -1;
        const newSelectedElementType = setElementType(selectedOption);
        const availableValueTypeOptions = getAvailableValueTypeOptions(valueTypes, selectedFactoryId, globalFactory?.id, newSelectedElementType ?? "");
        
        setValueTypeOptions(availableValueTypeOptions);

        return availableValueTypeOptions;
    }

    const handleElementTypeChange = (selectedOption) => {
        //console.log('handleElementTypeChange', selectedOption);
        let availableValueTypeOptions = setElementTypeAndGetAvailableValuetypeOptions(selectedOption);
        if (availableValueTypeOptions?.length === 1){
            const vt = setValueTypeFromOption(availableValueTypeOptions[0]);
            validateInputOrValueTypeFormulaAndSetValue(vt);
        }
        else {
            if (!selectedOption || !selectedOption.isConfig) {
                // Empty value type selection and therefore also values related to value type
                setValueTypeFromOption([]);
            }
        }
        trigger('elementType');// Validate the element type field!
        trigger('code'); // Validate the code field!
    }

    const handleInputChange = (value) => {
        //console.log('Change in input', value);
        setInputValue(value);
        setInputHelpText("");
        //setInputChanged(true);// for monaco editor
        setValue('input', value);
        if (value) {
            trigger('input');
        }
    }

    const setValueType = (valueType, vtOption, resetUserInputs = false) => {
        clearErrors('valueType'); //console.log('resetUserInputs & setValueType', resetUserInputs , valueType, selectedElement);
        clearErrors('input');
        setManualCalculatedValueError("");
        setSelectedValueType(valueType);
        setValue('valueType', vtOption);
        setValue('input', "");
        if (valueType && valueType.id > 0) {
            //console.log('changed value type', valueType);
            setValue('isGlobal', valueType.isGlobal);
            setValue('inputDefinedLater', valueType.inputDefinedLater);
            setValue('baseType', valueType.typeId);
            setValue('unitType', valueType.unitType);
            let unitRequired = isUnitRequired(valueType);
            setUnitRequired(unitRequired);
            setValue('unitRequired', unitRequired);
            if (resetUserInputs) {
                setInputValue("");
                setValue('input', ""); //console.log('valueType.userInputs', valueType.userInputs);
                setUserInputData(getUserInputsWithDefaultValues(valueType.userInputs));
                setCalculatedValue("");
                setValue('calculatedValue', "");
            }
            else {
                const newInputs = valueType.userInputs.map(input => ({
                    ...input,
                    value: input.typeId === UserInputType.BOOLEAN ?
                        input.value === 'true' :
                        input.value
                }));
                setUserInputData(newInputs); //console.log('newInputs', newInputs);
            }
            const inputShown = shouldInputFieldBeShown(valueType)
            setShowInput(inputShown);
            if (valueType.unitId) {
                const selectedUnit = getSelectedOption(unitOptions, valueType.unitId);
                setValue('unit', selectedUnit);
            }
            else {
                setValue('unit', {});
            }
            if (valueType.unitGroupId && valueType.unitGroupId > 0) {
                const filteredUnitOptions = getUnitOptions(units, valueType.unitGroupId);
                setFilteredUnitOptions(filteredUnitOptions);
                if (filteredUnitOptions && filteredUnitOptions.length === 1) {
                    setValue('unit', filteredUnitOptions[0]);
                }
            }
            else {
                setFilteredUnitOptions(unitOptions);
            }
        }
        else {
            setShowInput(true);
            setInputValue("");
            setValue('input', "");
            setUserInputData([]);
            setValue('unit', defaultValues.unit);
            setCalculatedValue("");
            setValue('calculatedValue', "");
            setValue('isGlobal', true);
            setValue('inputDefinedLater', false);
            setValue('baseType', -1);
            setUnitRequired(false);
            setValue('unitRequired', false);
            setFilteredUnitOptions(unitOptions);
        }
        trigger('valueType'); // Validate type
    }

    const setValueTypeFromOption = (selectedOption, inputs, resetUserInputs = true) => {
        //console.log('setValueTypeFromOption', selectedOption, inputs);
        clearErrors('valueType');
        if (!selectedOption || !selectedOption.value) {
            if (isConfigElement) {
                setValueType(valueTypes[0], valueTypeOptions[0], true);
            }
            else {
                setValueType({}, defaultValues.valueType, true);
            }
            return {};
        }
        
        let valueType = valueTypes.find(v => v.id === selectedOption.value); //console.log('found valueType', valueType);
        if (valueType?.userInputs?.length > 0 && inputs) {
            //console.log('inputs', inputs);
            // Let's set default values for value type inputs
            valueType?.userInputs.forEach(input => {
                //console.log('input', input);
                input.value = inputs.find(i => i.id === input.id)?.value ?? "";
            })
        }
        setValueType(valueType, selectedOption, resetUserInputs);
        return valueType;
    }

    const handleValueTypeChange = (selectedOption) => {
        setValue('input', ""); //console.log('handleValueTypeChange, reset input');
        const selectedValueType = setValueTypeFromOption(selectedOption); //console.log('handleValueTypeChange selectedValueType', selectedValueType);
        if (selectedValueType && selectedValueType.formula) {
            if (selectedValueType.formula) {
                validateFormula(selectedValueType.formula, getUserInputsWithDefaultValues(selectedValueType.userInputs), false);
            }
            else {
                setCalculatedValue("");
                setValue('calculatedValue', "");
            }
        }
    }

    const criterionOnBlur = (e) => {
        //console.log('onBlur', e.target.value, criterion);
        //setCriterion(criterion);
        setValue('criterion', criterion);
        //setCriterionChanged(true);// for monaco editor
        if (e.target.value) {
            validateCriterion(e.target.value);
        }
        trigger("criterion");
    }

    const isReadonlyOrConfig = () => {
        return status === Action.VIEW || isConfigElement;
    }

    const isItemOptionDisabled = (item) => {
        //console.log(status, getValues('item'));
        // No item is disabled if user is creating item and no item is yet selected.
        if (status === Action.CREATE && !getValues('item')?.value) {
            return false;
        }
        if (isConfigElement) {
            // Element is a configuration element.
            if (item.isConfig) {
                return false;
            }

            // User cannot change configuration element as regular one
            return true;
        }

        // The element is a regular element.
        if (item.isConfig) {
            // User cannot change regular element as a config one
            return true;
        }

        return false;
    }

    //useEffect(() => {// for monaco editor
    //    if (criterionChanged && criterion?.length > 0) {
    //        const timeoutId = setTimeout(() => validateCriterion(), 1000);
    //        return () => clearTimeout(timeoutId);
    //    }
    //}, [criterionChanged]); // eslint-disable-line react-hooks/exhaustive-deps

    //useEffect(() => {// for monaco editor
    //    if (inputChanged && inputValue?.length > 0) {
    //        const timeoutId = setTimeout(() => validateInput(), 1000);
    //        return () => clearTimeout(timeoutId);
    //    }
    //}, [inputChanged]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        //console.log('selectedElement changed!', selectedElement);
        setElementSectionDisabled(false);
        clearErrors();
        setInputHelpText('');
        setValue('notFirstVersion', notFirstVersion);
        const item = getSelectedItem(selectedItems, selectedElement); //console.log('item, isConfigElement', item, isConfigElement);
        setValue('item', item?.id ? { value: item.id, label: item.code } : {});
        const isConfig = item?.isConfig ?? false;
        setIsConfigElement(isConfig);
        let elementTypeOptions = getAvailableElementTypeOptions(selectedElementTypes, selectedElementTypeIds, isConfig);
        setElementTypeOptions(elementTypeOptions);
        const selectedElementTypeOption = getSelectedElementTypeOption(elementTypeOptions, selectedElement);

        if (selectedElement && selectedElement.id > 0) {
            setFactory(getSelectedOption(factoryOptions, selectedElement.factoryId));
            setIsSubstitutable(selectedElement.isSubstitutable);
            setValue('isSubstitutable', selectedElement.isSubstitutable);
            setConnectionTypeId(selectedElement.connectionType);
            setValue('connectionType', selectedElement.connectionType);
            setCriterion(selectedElement.criterion ?? "");
            setCriterionSelected(selectedElement.connectionType === 1);
            setValue("criterion", selectedElement.criterion ?? "");
            const vtOptions = setElementTypeAndGetAvailableValuetypeOptions(selectedElementTypeOption);
            setValue('code', selectedElement.code ?? selectedElement.label);
            const vtOption = selectedElement.valueType ? vtOptions.filter(o => o.value === selectedElement.valueType.id) : [];
            const selectedVTOption = vtOption?.length > 0 ? vtOption[0] : [];
            if (status !== Action.COPY) {
                setValue('id', selectedElement.id);
                setValueType(selectedElement.valueType, selectedVTOption); //console.log('selectedElement.valueType', selectedElement.valueType);
            }
            else {
                // We are copying - need to set the value type from storage ones to prevent referencing existing element value type.
                setValueTypeFromOption(selectedVTOption, selectedElement.valueType?.userInputs, false);
            }
            setInputValue(selectedElement.input ?? "");
            setValue('input', selectedElement.input ?? "")
            // In elements table N/A is shown as element value if formula or input contains Client parameter.
            // However in element modal the actual value is shown, so the formula/input needs evaluation.
            // Also if input field contains context parameter EDITMODE the input field needs evaluation
            //(the value of the parameter is true when edit modal is open, otherwise false).
            if (selectedElement.value === VALUE_NOT_CALCULABLE ||
                (selectedElement.input && selectedElement.input.match(/Context\(["']EDITMODE["']\)/))) {
                if (selectedElement.input) {
                    validateFormula(selectedElement.input); //console.log('validate input', selectedElement.input);
                }
                else {
                    validateFormula(selectedElement.valueType?.formula, selectedElement.valueType?.userInputs, false); //console.log('validate vt', selectedElement);
                }
            }
            else {
                setCalculatedValue(selectedElement.value ?? "");
                setValue('calculatedValue', selectedElement.value ?? "");
            }
            if (selectedElement.unitId && selectedElement.unitId > 0) {
                setValue('unit', getSelectedOption(unitOptions, selectedElement.unitId));
            }
            setValue('internalNotes', selectedElement.internalNotes ?? "");
            setValue('internalId', selectedElement.internalId ?? "");
            if (!createNewRevision && status !== Action.COPY) {
                setValue('comments', selectedElement.comments ?? ""); // If new revision is going to be created the comments field should be left empty!
            }
            else {
                setValue('comments', "");
            }
        }
        else {
            setUserInputData([]); //console.log('setUserInputData', "empty");
            setShowInput(true);
            if (factoryOptions.length === 1) {
                setFactory(factoryOptions[0]);
                const vtOptions = setElementTypeAndGetAvailableValuetypeOptions(elementTypeOptions.length === 1 || isConfig ? elementTypeOptions[0] : {});
                const vt = setValueTypeFromOption(vtOptions && vtOptions.length === 1 ? vtOptions[0] : []);
                // Validate input and set calculated value
                validateInputOrValueTypeFormulaAndSetValue(vt);
            }
            else {
                setFactory([]);
                setElementTypeAndGetAvailableValuetypeOptions(isConfig ? elementTypeOptions[0] : {});
                setValueTypeFromOption({});
                setFilteredUnitOptions(unitOptions);
                setCalculatedValue("");
                setValue('calculatedValue', "");
            }
            setIsSubstitutable(false);
            setValue('isSubstitutable', false);
            setConnectionTypeId(0);
            setValue('connectionType', 0);
            setCriterion("");
            setValue("criterion", "");
            setCriterionSelected(false);
            setInputValue("");
            setValue('internalNotes', "");
            setValue('internalId', "");
            setValue('comments', "");
        }
    }, [selectedElement, globalFactory, parameters, status, valueTypes, selectedFactoryIds, selectedElementTypeIds]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedItems?.length > 0) {
            if (selectedItems.length === 1 && selectedItems[0].isConfig) {
                setIsConfigElement(true);//console.log('is config')
            }
            else {
                setIsConfigElement(false); //console.log('is NOT config')
            }
        }
        else {
            setIsConfigElement(false); //console.log('is NOT config')
        }
    }, [selectedItems])

    if (!isOpen){
        return null;
    }
    
    return(
        <EntityModalBase
            isOpen={isOpen}
            toggle={toggle}
            status={status}
            className="modal-lg"
            titleBase="element"
            baseUrl={isConfigElement ? "element/config" : "element"}
            getData={getData}
            createNewRevision={createNewRevision}
            formIsValid={isValid}
            errors={errors}
            setError={setError}
            reset={reset}
            handleSubmit={handleSubmit}
            entityIsCreated={onEntityCreated}
            entityIsUpdated={onEntityUpdated}
            msalInstance={msalInstance}
        >
            <div className="border-bottom mb-2">
                <h4>Item</h4>
            </div>
            <FormGroup row className="required form-group">
                <Label for="item" sm={3}>Item code</Label>
                <Col sm={9}>
                    <Controller name="item" control={control} defaultValue={defaultValues.item} render={({field}) => 
                        <CustomSelect {...field} options={selectedItems.map(f => { return { value: f.id, label: f.code, isDisabled: isItemOptionDisabled(f) } })}
                            isDisabled={(status !== Action.COPY && selectedElement && selectedElement.itemId > 0) || selectedItems.length === 1 || isConfigElement}
                            onChange={handleItemChange}
                        />
                        }
                    />
                    {errors.item && <p className="text-danger">{errors.item.message}</p>}
                    {errors.item && errors.item.value && <p className="text-danger">{errors.item.value.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="description" sm={3}>Description</Label>
                <Col sm={9}>
                    <Controller name="description" control={control} defaultValue={defaultValues.description}
                        render={({field}) => <Input {...field} type="text" disabled />}
                    />
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="category" sm={3}>Category</Label>
                <Col sm={9}>
                    <Controller name="category" control={control} defaultValue={defaultValues.category}
                        render={({field}) => <Input {...field} type="text" disabled />}
                    />
                </Col>
            </FormGroup>
            <Controller name="id" control={control} defaultValue={defaultValues.id}
                render={({field}) => <Input {...field} type="hidden"/>}
            />
            <Controller name="notFirstVersion" control={control} defaultValue={defaultValues.notFirstVersion}
                render={({ field }) => <Input {...field} type="hidden" />}
            />
            <div className="border-bottom mb-2 mt-4">
                <h4>Connection (item - element)</h4>
            </div>
            <FormGroup row className="required form-group">
                <Label for="factory" sm={3}>Factory</Label>
                <Col sm={9}>
                    <Controller name="factory"
                        control={control}
                        defaultValue={defaultValues.factory}
                        render={({ field }) => 
                            <SelectWithStyles {...field}
                                options={factoryOptions}
                                isClearable
                                onChange={handleFactoryChange}
                                isDisabled={ status === Action.VIEW }
                            />
                        }
                    />
                    {errors.factory && <p className="text-danger">{errors.factory.message}</p>}
                </Col>
            </FormGroup>
            {globalFactorySelected && <FormGroup row className="form-group">
                <LabelWithTooltip
                    id="tooltipSubst"
                    labelFor="isSubstitutable"
                    tooltipInfoText="Global substitutable element will not be connected if there is a connectable non-global element with the same element code."
                >Is subst.</LabelWithTooltip>
                <Col sm={3} className='pt-2'>
                    <Controller name="globalFactoryId" control={control} defaultValue={defaultValues.globalFactoryId}
                        render={({field}) => <Input {...field} type="hidden"/>}
                    />
                    <Controller name="isSubstitutable" control={control} defaultValue={isSubstitutable} render={({field}) => 
                        <Input {...field}
                            type="checkbox"
                            onChange={handleIsSubstitutableChange}
                            checked={isSubstitutable}
                            disabled={isConfigElement}
                        />
                    }
                    />
                    {errors.isSubstitutable && <p className="text-danger">{errors.isSubstitutable.message}</p>}
                </Col>
            </FormGroup>}
            <FormGroup tag="fieldset" row className="form-group">
                <Label for="connectionType" sm={3}>Type</Label>
                <Col sm={9}>
                    <Controller name="connectionType" control={control} defaultValue={connectionTypeId} render={({field}) => 
                    <>
                        <FormGroup check className="mt-2 form-group">
                            <Label check>
                                <Input {...field} type="radio" name="connectionType" value={0} checked={connectionTypeId === 0}
                                    onChange={handleConnectionTypeChange}
                                />
                                <div className='ms-2'>{ConnectionType.ALWAYS}</div>
                            </Label>
                        </FormGroup>
                        <FormGroup check className="mt-2 form-group">
                            <Label check>
                                <Input {...field} type="radio" name="connectionType" value={1} checked={connectionTypeId === 1}
                                    onChange={handleConnectionTypeChange}
                                />
                                <div className='ms-2'>{ConnectionType.CRITERION}</div>
                            </Label>
                        </FormGroup>
                    </>
                    }
                    />
                </Col>
            </FormGroup>
            { criterionSelected && 
                <FormGroup row className="required form-group">
                    <LabelWithTooltip
                        id="tooltipCriterion"
                        labelFor="criterion"
                        iconUrl="./icons/lightning.svg"
                        tooltipText={"Scripting functionality enabled.\r\n" +
                            "Scripting runtime: " + runtimeText + "\r\n" +
                            "Expected output: boolean(true / false) \r\n" +
                            "Criterion that evalutes to 'true' enables the connection."}
                    >Criterion</LabelWithTooltip>
                    <Col sm={9}>
                        <div className={status === Action.VIEW ? "container-editor form-control disabled" : "container-editor form-control"}>
                            <Controller name="criterion"
                                control={control}
                                defaultValue={criterion}
                                render={({ field }) =>
                                    <CustomSimpleEditor {...field}
                                        id="criterion"
                                        value={criterion}
                                        onChange={criterion => setCriterion(criterion)}
                                        onBlur={criterionOnBlur}
                                        disabled={status === Action.VIEW}
                                        language={selectedStorage.scriptingRuntime}
                                    />
                                }
                                />
                            </div>
                        {criterionHelpText && <p className="text-primary">{criterionHelpText}</p>}
                        {errors.criterion && <p className="text-danger">{errors.criterion.message}</p>}
                    </Col>
                </FormGroup>
            }
            <div className="border-bottom mb-2 mt-4">
                <h4>Element</h4>
            </div>
            <FormGroup row className="required form-group">
                <Label for="elementType" sm={3}>Elem. code</Label>
                <Col sm={9}>
                    <FormGroup className="form-group">
                        <Controller name="elementType" control={control} defaultValue={defaultValues.elementType} render={({field}) => 
                            <SelectWithStyles {...field}
                                options={elementTypeOptions}
                                isClearable
                                onChange={handleElementTypeChange}
                                isDisabled={elementSectionDisabled || status === Action.VIEW}
                            />
                        }
                        />
                        <Controller name="code" control={control} defaultValue={defaultValues.code}
                            render={({field}) => <Input {...field} type="text" hidden={elementCodeHidden} className="mt-2" disabled={elementSectionDisabled || isReadonlyOrConfig()}/>}
                        />
                        {errors.elementType && <p className="text-danger">{errors.elementType.message}</p>}
                        {errors.code && <p className="text-danger">{errors.code.message}</p>}
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup row className="required form-group">
                {/*User cannot select a value type if the element is a configuration element (value type is hidden)!*/}
                {
                    !isConfigElement && <Label for="valueType" sm={3}>Type</Label>
                }
                <Col sm={9}>
                    <Controller name="baseType" control={control} defaultValue={defaultValues.baseType}
                        render={({field}) => <Input {...field} type="hidden"/>}
                    />
                    <Controller name="isGlobal" control={control} defaultValue={defaultValues.isGlobal}
                        render={({field}) => <Input {...field} type="hidden"/>}
                    />
                    <Controller name="inputDefinedLater" control={control} defaultValue={defaultValues.inputDefinedLater}
                        render={({field}) => <Input {...field} type="hidden"/>}
                    />
                    <Controller name="unitType" control={control} defaultValue={defaultValues.unitType}
                        render={({field}) => <Input {...field} type="hidden"/>}
                    />
                    <Controller name="unitRequired" control={control} defaultValue={unitRequired}
                        render={({field}) => <Input {...field} type="hidden"/>}
                    />
                    {isConfigElement ? <Controller name="valueType" control={control} defaultValue={defaultValues.valueType}
                        render={({ field }) => <Input {...field} type="hidden" />}
                    /> :
                        <Controller name="valueType" control={control} defaultValue={defaultValues.valueType} render={({ field }) =>
                            <SelectWithStyles {...field}
                                options={valueTypeOptions}
                                isClearable
                                onChange={handleValueTypeChange}
                                isDisabled={elementSectionDisabled || !selectedElementType || status === Action.VIEW}
                            />
                        }
                        />
                    }
                    {errors.valueType && <p className="text-danger">{errors.valueType.message}</p>}
                    {errors.valueType && errors.valueType.value && <p className="text-danger">{errors.valueType.value.message}</p>}
                </Col>
            </FormGroup>
            { showInput &&
                <FormGroup row className="required form-group">
                    <LabelWithTooltip
                        id="tooltipInput"
                        labelFor="input"
                        iconUrl="./icons/lightning.svg"
                        tooltipInfoText={isConfigElement ? "" : selectedValueType?.internalNotes}
                        tooltipText={(selectedValueType?.id > 0 && (selectedValueType?.isGlobal || selectedValueType?.inputDefinedLater)) ?
                            "Scripting functionality enabled.\r\n" +
                            "Scripting runtime: " + runtimeText + "\r\n" +
                            "Expected output: " +
                            (isConfigElement ? VALUE_NOT_CALCULABLE :
                            (selectedValueType ? getTypeById({ value: selectedValueType.typeId, toLowerCase: true }) : "")) : ""}
                    >Input</LabelWithTooltip>
                    <Col sm={9}>
                        <div className={(elementSectionDisabled || !selectedValueType || !selectedValueType.id || status === Action.VIEW) ? "container-editor form-control disabled" : "container-editor form-control"}
                            hidden={selectedValueType && selectedValueType.id > 0 && !selectedValueType.isGlobal && !selectedValueType.inputDefinedLater}
                        >
                            <Controller name="input"
                                control={control}
                                defaultValue={defaultValues.input}
                                render={({ field }) =>
                                    <CustomSimpleEditor {...field}
                                        id="input"
                                        defaultValue={defaultValues.input}
                                        onChange={inputValue => handleInputChange(inputValue)}
                                        onBlur={validateInput}
                                        disabled={elementSectionDisabled || !selectedValueType || !selectedValueType.id || status === Action.VIEW}
                                        language={selectedStorage.scriptingRuntime}
                                    />
                                }
                            />
                        </div>
                        <CustomTableWithEditableCells
                            tableColumns={UserInputColumns()}
                            tableData={userInputData}
                            updateCellData={updateCellData}
                            className={!userInputData || userInputData.length === 0 ? "d-none" : elementSectionDisabled ? "table-disabled" : ""}
                        />
                        {errors.input && <p className="text-danger">{errors.input.message}</p>}
                        <p className="text-primary mb-0">{inputHelpText}</p>
                        {manualCalculatedValueError && <p className="text-danger">{manualCalculatedValueError}</p>}
                    </Col>
                </FormGroup>
            }
            <FormGroup row className="form-group">
                <LabelWithTooltip
                    id="tooltipFormula"
                    labelFor="calculatedValue"
                    tooltipInfoText={selectedValueType?.formula}
                >Value</LabelWithTooltip>
                <Col sm={9}>
                    <Controller name="calculatedValue" control={control} defaultValue={calculatedValue}
                        render={({ field }) => <CalculatedValueInput {...field} type={selectedValueType.typeId} value={calculatedValue}/>}
                    />
                    {errors.calculatedValue && <p className="text-danger">{errors.calculatedValue.message}</p>}
                </Col>
            </FormGroup>
            { !isConfigElement && selectedValueType && selectedValueType.unitType > UnitType.NONE &&
                <FormGroup row className={unitRequired ? "required form-group" : "form-group"}>
                    <Label for="unit" sm={3}>Unit</Label>
                    <Col sm={9}>
                        <Controller name="unit" control={control} defaultValue={defaultValues.unit}
                            render={({ field }) =>
                                <SelectWithStyles {...field}
                                    options={filteredUnitOptions}
                                    isClearable
                                    isDisabled={(selectedValueType && selectedValueType.unitId > 0) || elementSectionDisabled || !selectedElementType || status === Action.VIEW}
                                />}
                        />
                        {errors.unit && <p className="text-danger">{errors.unit.message}</p>}
                        {errors.unit && errors.unit.value && <p className="text-danger">{errors.unit.value.message}</p>}
                    </Col>
                </FormGroup>
            }
            <div className="border-bottom mb-2 mt-4">
                <h4>Additional info</h4>
            </div>
            <FormGroup row className="form-group">
                <Label for="internalNotes" sm={3}>Internal notes</Label>
                <Col sm={9}>
                    <Controller name="internalNotes" control={control} defaultValue={defaultValues.internalNotes}
                        render={({ field }) =>
                            <TextareaAutosize {...field}
                                maxRows={10}
                                minRows={2}
                                className="form-control"
                            />}
                    />
                    {errors.internalNotes && <p className="text-danger">{errors.internalNotes.message}</p>}
                </Col>
            </FormGroup>
            <FormGroup row className="form-group">
                <Label for="internalId" sm={3}>Internal ID</Label>
                <Col sm={9}>
                    <Controller name="internalId" control={control} defaultValue={defaultValues.internalId}
                        render={({field}) => <Input {...field} type="text"/>}
                    />
                    {errors.internalId && <p className="text-danger">{errors.internalId.message}</p>}
                </Col>
            </FormGroup>
            <div className="border-bottom mb-2 mt-4">
                <h4>Revision info</h4>
            </div>
            <FormGroup row className={notFirstVersion ? "required form-group" : "form-group"}>
                <Label for="comments" sm={3}>Comments</Label>
                <Col sm={9}>
                    <Controller name="comments" control={control} defaultValue={defaultValues.comments}
                        render={({ field }) => <TextareaAutosize {...field} maxRows={10} minRows={2} className="form-control" />}
                    />
                    {errors.comments && <p className="text-danger">{errors.comments.message}</p>}
                </Col>
            </FormGroup>
        </EntityModalBase>
    )
}

export default ElementModal;